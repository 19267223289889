import type { GetterTree } from 'vuex';
import type { ETSILevels, ETSISubjects, ITSIState as TSIState } from '@/store/modules/tsi/types';
import type { RootState } from '@/store/types';

type TSIGetterTree = GetterTree<TSIState, RootState>;

export const getters: TSIGetterTree = {
  getTSILevel(state: TSIState): ETSILevels | null {
    const { currentLevel } = state;
    return currentLevel;
  },

  getTSISubject(state: TSIState): ETSISubjects | null {
    const { currentSubject } = state;
    return currentSubject;
  },

  getTSISection(state: any): any {
    if (state.currentLevel) {
      return state.levels['tsi-level-' + state.currentLevel];
    }
  },

  getTSISets(state: any): any {
    if (state.currentLevel && state.currentSubject) {
      let sets = state.levels['tsi-level-' + state.currentLevel][state.currentSubject].sets;

      if (state.currentSubject === 'writing') {
        const essays = state.levels['tsi-level-' + state.currentLevel]['essay'].sets;
        sets = Object.assign({}, sets, essays);
      }

      return sets;
    }

    return null;
  },

  sectionTime(state: TSIState): number {
    const { section } = state;
    if (section) {
      return section.time;
    }

    return 0;
  },

  essayTime(state: TSIState): number {
    const { essay } = state;
    if (essay) {
      return essay.time_remain || essay.time;
    }

    return 0;
  },

  isOngoingEssay(state: TSIState): number {
    const { essay } = state;
    if (essay && essay.is_completed) {
      return essay.is_completed;
    }

    return 0;
  },

  isOngoingSection(state: TSIState): boolean {
    const { section } = state;
    if (section && section.answers) {
      return Object.keys(section.answers).length > 0;
    }

    return false;
  },
};
