import type { BasicsPeriod } from '@/store/types';

export interface IFlowState {
  schedules: IFlowSchedules | null;
  subject: string | null;
  subject_label: string | null;
  num: number | null;
  wait: number | null;
  step: FlowStep | null;
  etl: boolean | null;
}

export enum FlowStatus {
  SectionStarted,
  ClockRunning,
}

export enum FlowStep {
  Wait = 'wait',
  Section = 'section',
  Review = 'review',
}

export interface IFlowSchedules {
  [key: string]: IFlowSchedule;
}

export interface IFlowSchedule {
  section_id: number;
  test_num: BasicsPeriod;
  num: number;
  subject_label: string;
  subject: string;
  ct: number;
  human_time: string;
  is_completed: 1 | 0;
}
