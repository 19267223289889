import type { MutationTree } from 'vuex';
import type { IErrorState } from '@/store/modules/error/types';

type ErrorMutationTree = MutationTree<IErrorState>;

export const mutations: ErrorMutationTree = {
  setError(state: IErrorState, arr: string[]) {
    state.error = true;
    state.errorArray = arr;
  },
  unsetError(state: IErrorState) {
    state.error = false;
    state.errorArray = [];
  },
};
