import { ApiCaller } from '@/ApiCaller';
import { Config } from '@/plugins/config';
import { ApiReactStudentCaller } from '@/ApiReactStudentCaller';

//accessToken
const authStorage = localStorage.getItem(Config.LocalStorage);

// Tries to get saved storage state
let authStorageParsed;
try {
  authStorageParsed = JSON.parse(authStorage as string);
} catch (err) {
  authStorageParsed = undefined;
}

// Sets default token to axios
if (authStorageParsed) {
  const accessToken = authStorageParsed.authModule.accessToken;
  if (accessToken) {
    ApiCaller.getInstance().setAuthorizationHeader(accessToken);
    ApiReactStudentCaller.getInstance().setAuthorizationHeader(accessToken);
  }
}
