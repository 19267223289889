import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import { type IFlowState, type IFlowSchedule, type IFlowSchedules, FlowStep } from './types';

type FlowGetterTree = GetterTree<IFlowState, RootState>;

const WAIT_ROUTE = '/assessment-wait';

const getAssessmentRoute = (schedlues: IFlowSchedules | null, subject: string | null, num: number | null): string => {
  let ret = '';
  if (!num) {
    return ret;
  }

  const schedule = subject && schedlues && schedlues[num.toString()];

  if (schedule && subject) {
    const test_num = schedule.test_num;
    ret = `/assessment/${subject.toLowerCase()}/${test_num}/${num}`;
  }

  return ret;
};

const getPreviousAssessemteRoute = (schedules: IFlowSchedules | null): string => {
  const schedule = Object.entries(schedules || {})
    .filter((s) => s[1].is_completed)
    .map((s) => s[1])
    .pop();

  if (schedule) {
    const subject = schedule.subject.toLowerCase();
    const test_num = schedule.test_num;
    const num = schedule.num;
    return `/assessment/${subject}/${test_num}/${num}`;
  }

  return '';
};

export const getters: FlowGetterTree = {
  goto(state: IFlowState): string {
    const { step, etl, subject, schedules, num } = state;

    if (etl && (step === null || step === FlowStep.Wait)) {
      return WAIT_ROUTE;
    } else if (step === FlowStep.Section) {
      return getAssessmentRoute(schedules, subject, num);
    } else if (step === FlowStep.Review) {
      return getPreviousAssessemteRoute(schedules);
    }

    return '';
  },

  wait(state: IFlowState): number {
    const { wait } = state;
    return wait ? +wait : 0;
  },

  etl(state: IFlowState): boolean {
    return !!state.etl;
  },

  canStart(state: IFlowState): boolean {
    const { wait, etl } = state;
    return !!(etl && wait != undefined && wait <= 0);
  },

  getPreviousSubject(state: IFlowState): string {
    const { schedules } = state;

    const subject = Object.entries(schedules || {})
      .filter((s) => s[1].is_completed)
      .map((s) => s[1].subject)
      .pop();
    return subject || '';
  },

  getPreviousSubjectLabel(state: IFlowState): string {
    const { schedules } = state;

    const subject_label = Object.entries(schedules || {})
      .filter((s) => s[1].is_completed)
      .map((s) => s[1].subject_label)
      .pop();
    return subject_label || '';
  },

  getPreviousNum(state: IFlowState): number | null {
    const { schedules } = state;
    const num = Object.entries(schedules || {})
      .filter((s) => s[1].is_completed)
      .map((s) => s[1].num)
      .pop();

    return num || null;
  },

  schedule(state: IFlowState): IFlowSchedules | {} {
    const { num, schedules } = state;

    if (!!schedules && !!num) {
      return schedules[num];
    }

    return {};
  },

  schedules(state: IFlowState): IFlowSchedule | {} {
    const { schedules } = state;
    return schedules || {};
  },

  isOngoingAssessment(state: IFlowState): boolean {
    const { schedules } = state;
    return Object.values(schedules || {}).some((s) => s.is_completed);
  },
};
