import type { AxiosError, AxiosResponse } from 'axios';
import type { FriendlyErrorResponse } from './types';

const defaultMessage = 'Something went wrong';

export default class ServerError extends Error {
  constructor(error: FriendlyErrorResponse, msg: string = '') {
    super(defaultMessage);

    const message = error.response?.data.message;

    this.originalError = error;
    this.name = 'ServerError';
    this.message = message || msg || defaultMessage;
    this.response = error.response ?? null;
    this.code = this.originalError.code || '';
    this.status = (this.response && this.response.status) || null;
  }

  public readonly response: AxiosResponse | null;
  public readonly originalError: AxiosError;
  public readonly status: number | null;
  public readonly code: string;
}
