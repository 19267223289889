import '@/assets/vue-argon-design-template/vendor/nucleo/css/nucleo.css';
import '@/assets/vue-argon-design-template/vendor/font-awesome/css/font-awesome.css';
import globalComponents from './global-components';
import globalDirectives from './global-directives';
import VueLazyload from 'vue-lazyload';

export default {
  install(Vue: any) {
    Vue.use(globalComponents);
    Vue.use(globalDirectives);
    Vue.use(VueLazyload);
  },
};
