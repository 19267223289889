import type { MutationTree } from 'vuex';
import type { IPeriods, ISectionState } from '@/store/modules/section/types';

type SectionMutationsTree = MutationTree<ISectionState>;

export const mutations: SectionMutationsTree = {
  setSections(state: ISectionState, periods: IPeriods) {
    state.periods = periods;
  },
};
