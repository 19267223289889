import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { IExamRoomAvg, IExamRoomState, IExamRoomSuperscore, IExamRoomWindows, ITests } from './types';

type ExamRoomGetterTree = GetterTree<IExamRoomState, RootState>;

export const getters: ExamRoomGetterTree = {
  getWindows(state: IExamRoomState): IExamRoomWindows {
    return state.windows;
  },

  getAverages(state: IExamRoomState): IExamRoomAvg | null {
    return state.averages;
  },

  getAssessments(state: IExamRoomState): ITests {
    return state.assessments;
  },

  getAssessmentsDiagnostic(state: IExamRoomState): ITests {
    return state.assessments_diagnostic;
  },

  getSuperscore(state: IExamRoomState): IExamRoomSuperscore | null {
    return state.superscore;
  },
};
