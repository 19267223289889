import { DateTime } from 'luxon';

// MIGRATE: Need refactory to support Vue3
// TODO: Format numbers
// TODO: Support others types of date formats
// TODO: Format time
export default {
  name: 'format',
  fn: (val: string) => DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
};
