import type { ITSIState } from '@/store/modules/tsi/types';

export const state: ITSIState = {
  levels: {},
  currentLevel: null,
  currentSubject: null,
  sections: [],
  section: null,
  feedback: null,
  questionLessons: null,
  answeredQuestions: null,
  pctReview: null,

  lessons: [],
  lessonsPctReview: null,
  currentLesson: null,

  essay: null,
};
