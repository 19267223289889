import type { GetterTree } from 'vuex';
import type { IScoreProgressionChart, IScoreProgressionState } from '@/store/modules/report/score-progression/types';
import type { RootState } from '@/store/types';

type ScoreProgressionGetter = GetterTree<IScoreProgressionState, RootState>;

export const getters: ScoreProgressionGetter = {
  getChart(state: IScoreProgressionState): IScoreProgressionChart | null {
    const { chart } = state;
    return chart;
  },
};
