import type { Route, RawLocation } from 'vue-router';
import type { RootState } from '@/store/types';
import type { Store } from 'vuex';
import type Router from 'vue-router';
import type { IUser } from '@/store/modules/auth/types';

export class RouteSwitch {
  private static instance: RouteSwitch | null = null;

  public static getInstance(store: Store<RootState>, router: Router): RouteSwitch {
    if (!RouteSwitch.instance) {
      RouteSwitch.instance = new RouteSwitch(store, router);
    }

    return RouteSwitch.instance;
  }

  protected constructor(private store: Store<RootState>, private router: Router) {}

  get getAccount(): IUser {
    return this.store.getters['authModule/account'];
  }

  get homeRoute(): string {
    return this.store.getters['authModule/homeRoute'];
  }

  get isExpired(): string {
    return this.store.getters['authModule/isExpired'];
  }

  get etl(): boolean {
    return this.store.getters['flowModule/etl'];
  }

  get flowRoute(): string {
    return this.store.getters['flowModule/goto'];
  }

  public getRoute(): string {
    return this.etl ? this.flowRoute : this.homeRoute;
  }

  public async next(to: Route, _from: Route, next: (to?: RawLocation) => void): Promise<void> {
    if (this.etl) {
      await this.checkFlow();
    }

    if (this.isExpired && to.name !== 'expired') {
      await this.router.replace('expired');
    } else if (this.getAccount.need_password_reset === 1 && to.name !== 'profile') {
      await this.router.replace('profile');
    } else if (this.etl) {
      this.flowRoute === to.path ? next() : next(this.flowRoute);
    } else if (to.name === 'root') {
      next(this.homeRoute);
    } else {
      next();
    }
  }

  private async checkFlow(): Promise<void> {
    try {
      await this.store.dispatch('flowModule/checkFlow');
    } catch (e: any) {
      if (e && e.status === 401) {
        await this.router.push('/logout');
      } else {
        throw e;
      }
    }
  }
}

export default (store: Store<RootState>, router: Router) => RouteSwitch.getInstance(store, router);
