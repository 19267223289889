import ServerError from './ServerError';
import type { FriendlyErrorResponse } from './types';

const message = 'An unknown error occurred. Check your internet connection and try again.';

export default class NetworkError extends ServerError {
  constructor(error: FriendlyErrorResponse) {
    super(error);
    this.name = 'NetworkError';
    this.message = message;
  }
}
