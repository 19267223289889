import type { ActionContext, ActionTree } from 'vuex';
import { ApiCaller } from '@/ApiCaller';
import type { RootState, Token } from '@/store/types';
import type { IGraduationPlannerState } from '@/store/modules/graduationplanner/types';

type GraduationPlannerActionContext = ActionContext<IGraduationPlannerState, RootState>;
type GraduationPlannerActionTree = ActionTree<IGraduationPlannerState, RootState>;

export const actions: GraduationPlannerActionTree = {
  async fetchSsoToken(context: GraduationPlannerActionContext): Promise<string> {
    const { data } = await ApiCaller.getInstance().get<Token>(`auth/bcp-token`);
    context.commit('setSsoToken', data.token);
    return data.token;
  },
};
