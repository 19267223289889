import type { MutationTree } from 'vuex';
import type { ITSIA2State, ITSIA2Window } from '@/store/modules/tsia2/types';
import type { IUSection } from '@/store/types';
import type { IAssessmentReviewQuestion, IQuestionLessons } from '@/store/modules/assessment/types';
import type { IPctReview } from '@/store/modules/studyhall/types';
import type { ITSIEssay } from '@/store/modules/tsi/types';

type TSIA2MutationsTree = MutationTree<ITSIA2State>;

export const mutations: TSIA2MutationsTree = {
  setTSIA2Windows(state: ITSIA2State, windows: ITSIA2Window[]) {
    state.windows = windows;
  },
  setTSIA2Section(state: ITSIA2State, section: IUSection) {
    state.section = section;
  },
  setTSIA2NextQuestionId(state: ITSIA2State, next_question_id: number | null) {
    state.next_question_id = next_question_id;
  },
  setPctReviewed(state: ITSIA2State, pctReviewed: IPctReview): void {
    state.pctReview = pctReviewed;
  },
  setAnsweredQuestions(state: ITSIA2State, questions: IAssessmentReviewQuestion[]) {
    state.answeredQuestions = questions;
  },
  setEssay(state: ITSIA2State, essay: ITSIEssay): void {
    state.essay = essay;
  },
  setEssayText(state: ITSIA2State, essayText: string): void {
    if (state.essay) {
      state.essay.essay = essayText;
    }
  },
  setQuestionLessons(state: ITSIA2State, lessons: IQuestionLessons[]) {
    state.questionLessons = lessons;
  },
  setDefaultSectionState(state: ITSIA2State) {
    state.section = null;
    state.section = null;
    state.next_question_id = null;
    state.answeredQuestions = null;
    state.pctReview = null;
    state.essay = null;
    state.questionLessons = null;
  },
};
