import type { ActionContext, ActionTree } from 'vuex';
import type { ITSIA2State } from '@/store/modules/tsia2/types';
import type { IAnswer, RootState } from '@/store/types';
import api from '@/ApiCaller';
import type { ITSIEssayPayload } from '@/store/modules/tsi/types';
import type { ILockSection } from '../assessment/types';

type TSIA2ActionContext = ActionContext<ITSIA2State, RootState>;
type TSIA2ActionTree = ActionTree<ITSIA2State, RootState>;
type NextQuestionId = { next_question_id: number };

export const actions: TSIA2ActionTree = {
  async fetchTSIA2Windows(context: TSIA2ActionContext): Promise<any> {
    const { data } = await api.get('tsi-homeroom/sections');

    context.commit('setTSIA2Windows', data);
  },

  async takeSection(context: TSIA2ActionContext, params: any): Promise<any> {
    await context.dispatch('resetSectionState');

    const { data } = await api.get<NextQuestionId>(
      `tsi-assessment/take-section/${params.subject}/${params.testNumber}/${params.testType}`
    );

    context.commit('setTSIA2Section', data);
    context.commit('setTSIA2NextQuestionId', data.next_question_id || null);
  },

  async saveAnswer(context: TSIA2ActionContext, payload: IAnswer): Promise<any> {
    const { data } = await api.post<NextQuestionId, IAnswer>('tsi-assessment/save-answer', payload);

    context.commit('setTSIA2NextQuestionId', data.next_question_id || null);
  },

  async finishSection(context: TSIA2ActionContext, payload: { usectionId: number }): Promise<any> {
    await api.post('tsi-assessment/finish-section', { usection_id: payload.usectionId });
    const { section } = context.state;
    if (section) context.commit('setTSIA2Section', { ...section, is_completed: 1 });
  },

  async fetchPctReviewed(context: TSIA2ActionContext, params: any): Promise<any> {
    const pctReviewed = await api.get(
      `tsi-assessment/pct-reviewed/${params.subject}/${params.testNumber}/${params.testType}`
    );

    context.commit('setPctReviewed', pctReviewed.data);
  },

  async fetchAnsweredQuestions(context: TSIA2ActionContext, params: any): Promise<any> {
    const content = await api.get(
      `/tsi-assessment/review-questions/${params.subject}/${params.testNumber}/${params.testType}`
    );

    context.commit('setAnsweredQuestions', content.data);
  },

  async reviewQuestion(_: TSIA2ActionContext, payload: {}): Promise<any> {
    await api.post('/tsi-assessment/question-viewed', payload);
  },

  async fetchQuestionLessons(context: TSIA2ActionContext, question_id: number): Promise<any> {
    const lessons = await api.get(`tsi-assessment/question-lessons/${question_id}`);
    context.commit('setQuestionLessons', lessons.data);
  },

  async fetchEssay(context: TSIA2ActionContext, testNumber: number): Promise<any> {
    const content = await api.get(`/tsi-assessment/take-section/essay/${testNumber}`);
    context.commit('setEssay', content.data);
  },

  async lockSection(context: TSIA2ActionContext, payload: ILockSection): Promise<void> {
    await api.post(`${context.rootState.productPrefix}assessment/lock-section`, {
      usection_id: payload.usection_id,
      is_locked: payload.is_locked,
      locked_by: payload.locked_by,
    });
  },

  async ksaFetchEssay(context: TSIA2ActionContext, testNumber: number): Promise<any> {
    const content = await api.get(`/courses-assessment/take-section/essay/${testNumber}`);
    context.commit('setEssay', content.data);
  },

  async saveEssayText(context: TSIA2ActionContext, essayText: string): Promise<any> {
    context.commit('setEssayText', essayText);
  },

  async saveEssay(_: TSIA2ActionContext, payload: ITSIEssayPayload): Promise<any> {
    await api.post('tsi-assessment/save-essay', payload);
  },

  async ksaSaveEssay(_: TSIA2ActionContext, payload: ITSIEssayPayload): Promise<any> {
    await api.post('courses-assessment/save-essay', payload);
  },

  async gradeEssay(_: TSIA2ActionContext, payload: ITSIEssayPayload): Promise<any> {
    await api.post('tsi-assessment/grade-essay', payload);
  },

  async ksaGradeEssay(_: TSIA2ActionContext, payload: ITSIEssayPayload): Promise<any> {
    await api.post('courses-assessment/grade-essay', payload);
  },

  async resetSectionState(context: TSIA2ActionContext): Promise<any> {
    context.commit('setDefaultSectionState');
  },
};
