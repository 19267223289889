import Vue from 'vue';
import VueCookies from 'vue-cookies';
import Vuex, { type StoreOptions } from 'vuex';
import vuexLocal from '@/plugins/vuex-persist';
import type { RootState } from '@/store/types';
import { authModule } from '@/store/modules/auth';
import { sectionModule } from '@/store/modules/section';
import { examRoomModule } from '@/store/modules/examroom';
import { studyHallModule } from '@/store/modules/studyhall';
import { reportModule } from '@/store/modules/report';
import { profileModule } from '@/store/modules/profile';
import { errorModule } from '@/store/modules/error';
import { quizModule } from '@/store/modules/quiz';
import { contentModule } from '@/store/modules/content';
import { assessmentModule } from '@/store/modules/assessment';
import { tsiModule } from '@/store/modules/tsi';
import { tsia2Module } from '@/store/modules/tsia2';
import { flowModule } from '@/store/modules/flow';
import { vocabularyBuilderModule } from '@/store/modules/vocabulary-builder';
import { practiceModule } from '@/store/modules/practice';
import type { IAuthState } from './store/modules/auth/types';
import { Config, getConfig } from './plugins/config';
import { actions } from '@/store/actions';
import { mutations } from '@/store/mutations';
import { getters } from '@/store/getters';
import { graduationPlannerModule } from '@/store/modules/graduationplanner';
import { assignmentModule } from '@/store/modules/assignments';
import { scheduleModule } from '@/store/modules/schedule';
import { timelineModule } from '@/store/modules/timeline';
import { toolsModule } from '@/store/modules/tools';

Vue.use(Vuex);
Vue.use(VueCookies);

const storeOption: StoreOptions<RootState> = {
  state: {
    appName: 'CERT CSI',
    appVersion: '0.0.1',
    theme: Config.DefaultTheme,
    isOnline: true,
    product: '',
    productPrefix: '',
    isLoading: false,
  },
  actions,
  mutations,
  getters,
  modules: {
    assignmentModule,
    assessmentModule,
    tsiModule,
    tsia2Module,
    authModule,
    contentModule,
    errorModule,
    examRoomModule,
    graduationPlannerModule,
    flowModule,
    profileModule,
    quizModule,
    reportModule,
    sectionModule,
    studyHallModule,
    vocabularyBuilderModule,
    practiceModule,
    scheduleModule,
    timelineModule,
    toolsModule,
  },

  // @ts-ignore
  plugins: [vuexLocal.plugin],
};

const store = new Vuex.Store<RootState>(storeOption);

//TODO Create a way to get all initial states from each module
//without need register or import each one manually
const initialAuthState: IAuthState = {
  accessToken: '',
  isLogged: false,
  account: null,
  isCompleted: false,
  referrerURL: null,
};

const initialStateCopy = JSON.stringify({
  ...store.state,
  // enforce a clean auth state after reload.
  // It is necessary because the auth data
  // are held and recovery from browser's local storage.
  authModule: initialAuthState,
});

export function resetStore() {
  const state = JSON.parse(initialStateCopy);
  const theme = store.state.theme;
  store.replaceState({ ...state, ...{ theme } });
}

export default store;
