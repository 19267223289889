import type { ActionContext, ActionTree } from 'vuex';
import type { ILessonPerformanceReportState } from '@/store/modules/report/lesson-performance-report/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type LessonPerformanceReportContext = ActionContext<ILessonPerformanceReportState, RootState>;
type LessonPerformanceReportTree = ActionTree<ILessonPerformanceReportState, RootState>;

export const actions: LessonPerformanceReportTree = {
  async fetchLessonPerformance(context: LessonPerformanceReportContext): Promise<void> {
    const { data } = await ApiCaller.getInstance().get('report/lesson-performance');
    context.commit('setLessonPerformance', data);
  },
};
