import type { Module } from 'vuex';
import { state } from './state';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import type { IProfileState } from '@/store/modules/profile/types';
import type { RootState } from '@/store/types';

const namespaced: boolean = true;

type ProfileModule = Module<IProfileState, RootState>;

export const profileModule: ProfileModule = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
