import type { ActionContext, ActionTree } from 'vuex';
import type { AssignmentUpdate, IScheduleState, Schedule } from '@/store/modules/schedule/types';
import type { RootState } from '@/store/types';
import api, { ApiCaller } from '@/ApiCaller';

type ScheduleContext = ActionContext<IScheduleState, RootState>;
type ScheduleTree = ActionTree<IScheduleState, RootState>;

export const actions: ScheduleTree = {
  async fetchSchedule(context: ScheduleContext): Promise<any> {
    const { data } = await ApiCaller.getInstance().get('schedule');
    context.commit('setSchedule', data);
  },

  async fetchScheduleTests(context: ScheduleContext): Promise<any> {
    const scheduleTests = await ApiCaller.getInstance().get('schedule/tests');
    context.commit('setScheduleTests', scheduleTests.data);
  },

  async createSchedule(context: ScheduleContext, payload: Schedule): Promise<void> {
    const { data } = await api.post('schedule', payload);
    context.commit('setSchedule', data);
  },

  async updateAssignment(context: ScheduleContext, payload: AssignmentUpdate): Promise<void> {
    await api.put(`schedule/assignments/${payload.id}`, payload);
  },
};
