import type { ActionContext, ActionTree } from 'vuex';
import type { IProfileAccountInformation, IProfileState } from '@/store/modules/profile/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type ProfileActionContext = ActionContext<IProfileState, RootState>;
type ProfileActionTree = ActionTree<IProfileState, RootState>;

export const actions: ProfileActionTree = {
  async fetchProfile(context: ProfileActionContext): Promise<any> {
    const prefix = context.rootState.product === 'tsi' ? '' : context.rootState.productPrefix;
    const response = await ApiCaller.getInstance().get(`${prefix}homeroom/profile`);
    context.commit('setProfile', response.data);
  },

  async updateProfile(context: ProfileActionContext, payload: IProfileAccountInformation): Promise<any> {
    const prefix = context.rootState.product === 'tsi' ? '' : context.rootState.productPrefix;
    await ApiCaller.getInstance().patch(`${prefix}homeroom/profile`, payload);
  },

  async fetchProfileMeta(context: ProfileActionContext): Promise<any> {
    const response = await ApiCaller.getInstance().get(`${context.rootState.productPrefix}homeroom/profile-meta`);
    context.commit('setProfileMeta', response.data);
  },

  async updateProfileMeta(context: ProfileActionContext, payload: IProfileAccountInformation): Promise<any> {
    await ApiCaller.getInstance().post(`${context.rootState.productPrefix}homeroom/profile-meta`, payload);
  },
};
