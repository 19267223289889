import type { IAssessmentState } from '@/store/modules/assessment/types';

export const state: IAssessmentState = {
  sections: [],
  section: null,
  feedback: null,
  feedbackStandards: null,
  questionLessons: null,
  answeredQuestions: null,
  pctReview: null,
  performance: null,
  assignments: [],
};
