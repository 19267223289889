import { parseDomain } from 'parse-domain';

export interface AppConfig {
  Env: string;
  Api: string;
  ApiReactStudent: string;
  Themes: ThemesConfig;
  DefaultTheme: ThemeConfig;
  LocalStorage: string;
  TimerOff: boolean;
  ApiQTA: string;
  LegacyAddress: string;
  LocalStorageQTA: string;
  SystemUid: number;
}

export interface ThemeConfig {
  domains: string[];
  legacyAddress?: string | null;
  legacyAddressDemo?: string | null;
  api: string;
  apiReactStudent: string;
  component: string;
  name: string;
  title: string;
}

export interface ThemesConfig {
  [key: string]: ThemeConfig;
}

const defaultTheme: ThemeConfig = {
  domains: ['localhost:8041', 'certforschools.com'],
  legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_DEFAULT || null,
  api: import.meta.env.VITE_APP_API_DEFAULT || null,
  apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
  component: 'DefaultTheme',
  name: 'cert',
  title: 'CERT | College Equipped Readiness Tool',
};

export const getConfig = (): AppConfig => {
  return Config;
};

const themes: ThemesConfig = {
  cert: defaultTheme,
  cpa: {
    domains: ['localhost:8181', 'cpa-student-stage.certforschools.com', 'testing.collegeprepalabama.com'],
    legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_CPA || null,
    api: import.meta.env.VITE_APP_API_DEFAULT || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'CollegePrepAlabama',
    name: 'cpa',
    title: 'College Prep Alabama',
  },
  eprep: {
    domains: ['localhost:8042', 'student-stage.eprep.com', 'student.eprep.com'],
    legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_EPREP || null,
    api: import.meta.env.VITE_APP_API_EPREP || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'Eprep',
    name: 'eprep',
    title: 'ePrep',
  },
  hop: {
    domains: [
      'localhost:8043',
      'student.huntingtononlineprep.com',
      'huntingtononlineprep.com',
      'hop-student.eprepdev.com',
      'hop-student-stage.eprep.com',
      'hop-student.eprep.com',
    ],
    legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_HOP || null,
    legacyAddressDemo: import.meta.env.VITE_APP_LEGACY_URL_HOP_DEMO || null,
    api: import.meta.env.VITE_APP_API_HOP || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'Hop',
    name: 'hop',
    title: 'Huntington Online Prep',
  },
  sylvan: {
    domains: [
      'localhost:8044',
      'student.sylvanprep.com',
      'sylvanprep.com',
      'sylvan-student.eprepdev.com',
      'sylvan-student-stage.eprep.com',
      'student-stage.sylvanprep.com',
      'sylvan-student.eprep.com',
    ],
    legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_SYLVAN || null,
    api: import.meta.env.VITE_APP_API_SYLVAN || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'Sylvan',
    name: 'sylvan',
    title: 'Sylvan Learning',
  },
  trackit: {
    domains: ['localhost:8045', 'student.trackit-testprep.com'],
    legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_TRACKIT || null,
    api: import.meta.env.VITE_APP_API_EPREP || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'TrackIt',
    name: 'trackit',
    title: 'Track It! Test Prep',
  },
  clubz: {
    domains: ['localhost:8046', 'student.zpreponline.com'],
    legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_CLUBZ || null,
    api: import.meta.env.VITE_APP_API_EPREP || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'ClubZ',
    name: 'clubz',
    title: 'Z Prep!',
  },
  athleteprep: {
    domains: ['localhost:8047', 'student.hsstudentprep.com', 'student.soccerprep.com', 'student.lacrosseprep.com'],
    legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_ATHLETEPREP || null,
    api: import.meta.env.VITE_APP_API_EPREP || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'AthletePrep',
    name: 'athleteprep',
    title: 'Athlete Prep',
  },
  uscore: {
    domains: ['localhost:8048', 'student.uscoretestprep.com'],
    legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_USCORE || null,
    api: import.meta.env.VITE_APP_API_EPREP || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'UScore',
    name: 'uscore',
    title: 'uScore Test Prep',
  },
  cahelp360: {
    domains: ['localhost:8049', 'cahelp360-student.eprep.com'],
    legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_CAHELP360 || null,
    api: import.meta.env.VITE_APP_API_EPREP || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'CaHelp360',
    name: 'cahelp360',
    title: 'Ca Help 360',
  },
  rc3: {
    domains: ['localhost:8050', 'rightc3-student.eprep.com'],
    legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_RC3 || null,
    api: import.meta.env.VITE_APP_API_EPREP || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'RC3',
    name: 'rc3',
    title: 'RC3',
  },
  caa: {
    domains: ['localhost:8051', 'caa-student.eprep.com'],
    legacyAddress: import.meta.env.VITE_APP_LEGACY_URL_CAA || null,
    api: import.meta.env.VITE_APP_API_EPREP || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'Caa',
    name: 'caa',
    title: 'CAA!',
  },
  thrivent: {
    domains: ['localhost:8052', 'thrivent.com'],
    legacyAddress: null,
    api: import.meta.env.VITE_APP_API_EPREP || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'Thrivent',
    name: 'thrivent',
    title: 'Thrivent',
  },
  libraries: {
    domains: ['localhost:8053', 'libraries.eprep.com'],
    legacyAddress: null,
    api: import.meta.env.VITE_APP_API_EPREP || null,
    apiReactStudent: import.meta.env.VITE_APP_API_REACT_STUDENT || null,
    component: 'Libraries',
    name: 'libraries',
    title: 'Libraries',
  },
};

export const getThemeKeyByHostName = (hostName: string, themes: ThemesConfig): string | undefined => {
  const finder = (hn: string) =>
    Object.keys(themes).find((themeKey) => {
      const themeObj = themes[themeKey];
      return (
        themeObj.domains.includes(hn) ||
        (import.meta.env.VITE_APP_FORCED_THEME === themeKey && import.meta.env.VITE_APP_ENV === 'dev')
      );
    });

  let ret = finder(hostName);

  if (!ret) {
    const parseResult = parseDomain(window.location.hostname);
    const parsedHostName = parseResult.hostname as string;
    const hostPort = window.location.port;
    const port = hostPort ? `:${hostPort}` : '';
    hostName = parsedHostName + port;

    ret = finder(hostName);
  }

  return ret;
};

const themeKey = getThemeKeyByHostName(window.location.hostname, themes) || 'cert';
const theme = themes[themeKey];

const Config: AppConfig = {
  Env: import.meta.env.VITE_APP_ENV,
  Api: theme.api,
  ApiReactStudent: theme.apiReactStudent,
  Themes: themes,
  DefaultTheme: defaultTheme,
  LocalStorage: import.meta.env.VITE_APP_LOCAL_STORAGE + '-' + themeKey,
  TimerOff: false,
  ApiQTA: import.meta.env.VITE_APP_QTA_API,
  LegacyAddress: import.meta.env.VITE_APP_LEGACY_ADDRESS_URL,
  LocalStorageQTA: themeKey + '-' + import.meta.env.VITE_APP_QTA_LOCAL_STORAGE,
  SystemUid: +import.meta.env.VITE_APP_SYSTEM_UID,
};

export { Config };

export default {
  install(Vue: any) {
    Vue.AppConfig = Config;
    Vue.prototype.$AppConfig = Config;
  },
};
