import type { ActionContext, ActionTree } from 'vuex';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';
import { FlowStep, FlowStatus, type IFlowState, type IFlowSchedules, type IFlowSchedule } from './types';
import type { IUser } from '@/store/modules/auth/types';
import { getProductSlug } from '@/helpers';

type FlowActionContext = ActionContext<IFlowState, RootState>;
type FlowActionTree = ActionTree<IFlowState, RootState>;

const api = ApiCaller.getInstance();

export const actions: FlowActionTree = {
  async start(context: FlowActionContext) {
    const response = await api.get<{ etl: boolean }>('flow/start');
    const etl = response.data.etl as unknown as 0 | 1;

    if (etl) {
      await context.dispatch('fetchSchedules');
    }

    context.commit('setEtl', etl);

    const data: IUser = context.rootGetters['authModule/account'];
    const permissions = data.permissions;
    if (permissions) {
      permissions.enforcedTimeLimits = etl;
    }

    const currentProduct = getProductSlug(data.activeProduct?.product_type || 'act');
    await context.dispatch('authModule/setUser', data, { root: true });

    let product;

    if (etl) {
      product = permissions?.enforcedTimeLimitsCourse ? data.permissions?.enforcedTimeLimitsCourse : 'act';
    } else {
      if (currentProduct) {
        if (
          currentProduct === 'act' &&
          (permissions?.homeRoom || data.diagnosticProduct?.product_type.toLowerCase() === currentProduct) // TODO: not ideal
        ) {
          product = 'act';
        }

        if (
          currentProduct === 'dsat' &&
          (permissions?.homeRoom || data.diagnosticProduct?.product_type.toLowerCase() === currentProduct) // TODO: not ideal
        ) {
          product = 'dsat';
        }

        if (currentProduct === 'gp' && permissions?.gp) {
          product = 'gp';
        }

        if (!product && permissions?.homeRoom) {
          product = currentProduct;
        }
      }
    }

    await context.dispatch('setProduct', product, { root: true });
  },

  async setEtl(context: FlowActionContext, payload: boolean) {
    context.commit('setEtl', payload);
  },

  async fetchSchedules(context: FlowActionContext) {
    if (context.state.schedules) return;

    const { data } = await api.get<any>('flow/schedule');
    const { sections } = data.schedule;
    const schedules = Object.values(sections as IFlowSchedules).reduce(
      (a: IFlowSchedules, c: IFlowSchedule) => ({
        ...a,
        [c.num.toString()]: c,
      }),
      {}
    );

    context.commit('setSchedules', schedules);
  },

  async checkFlow(context: FlowActionContext) {
    if (context.state.etl === null) {
      const { data } = await api.get<any>('flow/next');
      const { wait } = data;

      context.commit('setEtl', data.etl);

      if (wait !== undefined && +wait <= 0) {
        const { etl, step } = context.state;

        if (etl && !step) {
          const { data } = await api.get<any>('flow/next');

          const state: Partial<IFlowState> = {
            etl: data.etl,
            subject: data.subject,
            subject_label: data.subject_label,
            num: +data.num,
            wait: data.wait || 0,
            step: FlowStep.Wait,
          };

          context.commit('setSection', state);
        }
      }
    }
  },

  async startSection(context: FlowActionContext) {
    await context.dispatch('fetchSchedules');
    const { data } = await api.get<any>('flow/next');
    const { num, subject, subject_label } = context.state;

    const state: Partial<IFlowState> = {
      etl: data.etl,
      subject: data.subject,
      subject_label: data.subject_label,
      num: +data.num,
      wait: +data.wait,
      step: FlowStep.Wait,
    };

    // Holding last
    if (!data.num && !data.subject && data.wait) {
      state.subject = subject;
      state.subject_label = subject_label;
      state.num = num;
    }

    context.commit('setSection', state);
  },

  async takeSection(context: FlowActionContext, status: FlowStatus) {
    const { schedules, num, subject } = context.state;

    if (schedules && num && subject) {
      const test = schedules[num].test_num;
      const { data } = await api.get<any>(
        `flow/start-section/${subject.toLowerCase()}/${test}/${num}/${
          status === FlowStatus.SectionStarted ? 'section-started' : 'clock-started'
        }`
      );

      context.commit('setTakeSection', {
        etl: data.etl,
        wait: data.wait,
        step: data.wait ? FlowStep.Wait : FlowStep.Section,
      });
    }
  },

  async checkSectionWaitTime(context: FlowActionContext) {
    const { data } = await api.get<any>('flow/next');
    context.commit('setWait', data.wait);

    //Releasing last
    if (!data.subject && !data.num && !data.wait) {
      await context.dispatch('finishSection');
    }
  },

  async finishSection(context: FlowActionContext, subject: string) {
    const { data } = await api.get<any>('flow/next');
    const { schedules, num } = context.state;

    if (schedules && subject && num) {
      const schedule = schedules[num.toString()];
      schedule.is_completed = 1;

      context.commit('finishSection', {
        schedule,
        step: FlowStep.Wait,
        subject: subject.toLowerCase(),
        num: num,
      });
    }

    if (!data.etl) {
      context.commit('finishFlow');
      await context.dispatch('authModule/permissions', {}, { root: true });
    }
  },

  async previous(context: FlowActionContext, remainingTime: number) {
    const { data } = await api.get<any>(`flow/previous/${remainingTime}`);
    const { subject, subject_label, num } = context.state;

    if (data.url === 'flow/next') {
      context.commit('setPrevious', {
        step: FlowStep.Wait,
        subject: subject,
        subject_label: subject_label,
        num: num,
      });
    } else {
      context.commit('setPrevious', {
        step: FlowStep.Review,
        subject: context.getters['getPreviousSubject'],
        subject_label: context.getters['getPreviousSubjectLabel'],
        num: context.getters['getPreviousNum'],
      });
    }
  },

  async setStep(context: FlowActionContext, payload: FlowStep | null) {
    context.commit('setStep', payload);
  },
};
