import type { ActionContext, ActionTree } from 'vuex';
import type { IAuthState, ICredentials } from '@/store/modules/auth/types';
import type { ApiKey, RootState, Token } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type AuthActionContext = ActionContext<IAuthState, RootState>;
type AuthActionTree = ActionTree<IAuthState, RootState>;

const api = ApiCaller.getInstance();

export const actions: AuthActionTree = {
  async legacySso(_context: AuthActionContext, payload: { unqid: string; customUrl: string }): Promise<any> {
    const response = await api.post<Token, { unqid: string; customUrl: string }>('auth/legacy-sso', {
      unqid: payload.unqid,
      customUrl: payload.customUrl,
    });
    return response.data;
  },

  async switchCourse(context: AuthActionContext, unqid: string): Promise<any> {
    const { data } = await api.post<Token, { unqid: string }>('auth/switch-course', { unqid: unqid });
    await context.dispatch('doLogin', data.token || data);
  },

  async login(context: AuthActionContext, payload: ICredentials): Promise<any> {
    const { data } = await api.post<Token, ICredentials>('auth/login', payload);
    await context.dispatch('doLogin', data.token);
  },

  async apiLogin(context: AuthActionContext, payload: ApiKey): Promise<any> {
    const { data } = await api.post<Token, ApiKey>('auth/api-login', payload);
    await context.dispatch('doLogin', data.token);
  },

  async doLogin(context: AuthActionContext, token: string): Promise<any> {
    context.commit('setToken', token);

    const urlParams = new URLSearchParams(window.location.search);
    const referrer = context.getters['getReferrerURL'] || urlParams.get('referer');

    await context.dispatch('setReferrerURL', referrer);

    await api.get('auth/sections');
    await context.dispatch('permissions');
    await context.dispatch('flowModule/start', {}, { root: true });
  },

  async unsetToken(context: AuthActionContext): Promise<void> {
    context.commit('unsetToken');
  },

  async recoveryPassword(_: AuthActionContext, username: string): Promise<any> {
    await api.post('auth/password-recovery', { username });
  },

  async permissions(context: AuthActionContext): Promise<any> {
    const { data } = await api.get<any>('auth/permissions');

    data.permissions.homeRoomOn = 1;

    context.commit('setUser', data);
    context.commit('setLogged', true);
  },

  async setUser(context: AuthActionContext, payload) {
    context.commit('setUser', payload);
  },

  async updatePasswordNeeded(context: AuthActionContext, payload) {
    context.commit('updatePasswordNeeded', payload);
  },

  async checkHomeRoomOn(context: AuthActionContext): Promise<void> {
    const { data } = await api.get<any>('auth/home-room-on');

    context.commit('setHomeRoomOn', data.homeRoomOn);
  },

  async complete(context: AuthActionContext) {
    context.commit('complete');
  },

  async setReferrerURL(context: AuthActionContext, referrerURL) {
    context.commit('setReferrerURL', referrerURL);
  },
};
