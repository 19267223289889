import ServerError from './ServerError';
import type { FriendlyErrorResponse } from './types';

export default class BetaRedirectionError extends ServerError {
  constructor(error: FriendlyErrorResponse) {
    super(error);

    this.name = 'BetaRedirectionError';
    this.url = error.response?.data?.url || '';
  }

  public readonly url: string;
}
