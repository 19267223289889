import type { MutationTree } from 'vuex';
import type { IActionableReportState, IAdaptiveReport } from '@/store/modules/report/actionable-report/types';

type ActionableReportTree = MutationTree<IActionableReportState>;
type ActionableAdaptiveReportPayload = { window: number; report: IAdaptiveReport };

export const mutations: ActionableReportTree = {
  setActionableReport(state: IActionableReportState, report: any) {
    state.report = report;
  },
  setActionableAdaptiveReport(state: IActionableReportState, payload: ActionableAdaptiveReportPayload) {
    if (!state.adaptive_report) {
      state.adaptive_report = new Map<number, IAdaptiveReport>();
    }

    state.adaptive_report.set(payload.window, payload.report);
  },
};
