import type { GetterTree } from 'vuex';
import type {
  LessonPerformanceData,
  ILessonPerformanceReportState,
} from '@/store/modules/report/lesson-performance-report/types';
import type { RootState } from '@/store/types';

type LessonPerformanceReportGetter = GetterTree<ILessonPerformanceReportState, RootState>;

export const getters: LessonPerformanceReportGetter = {
  getLessonPerformance(state: ILessonPerformanceReportState): LessonPerformanceData | null {
    const { lessonPerformance } = state;
    return lessonPerformance;
  },
};
