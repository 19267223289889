import type { GetterTree } from 'vuex';
import type { IActionableReportState, IAdaptiveReport } from '@/store/modules/report/actionable-report/types';
import type { RootState } from '@/store/types';

type ActionableReportGetter = GetterTree<IActionableReportState, RootState>;

export const getters: ActionableReportGetter = {
  getActionableReport(state: IActionableReportState): any {
    const { report } = state;
    return report;
  },

  getActionableAdaptiveReport(state: IActionableReportState): any {
    const { adaptive_report } = state;

    if (!adaptive_report) {
      return new Map<number, IAdaptiveReport>();
    }

    return adaptive_report;
  },
};
