import type { Module } from 'vuex';
import { state } from './state';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import type { IVocabularyBuilderState } from '@/store/modules/vocabulary-builder/types';
import type { RootState } from '@/store/types';

const namespaced: boolean = true;

export const vocabularyBuilderModule: Module<IVocabularyBuilderState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
