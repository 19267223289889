<template>
  <div v-if="httpStatus" data-testid="return-msg">
    <base-alert :type="msgType" :visible="httpStatus" :dismissible="dismissible" @update:visible="updateVisible">
      <span slot="text">
        <ul class="list-unstyled m-0" v-if="isMsgStr">
          <li>
            {{ msgStr }}
          </li>
        </ul>
        <ul class="list-unstyled m-0" v-else>
          <li v-for="item in msg" v-bind:key="item.id">
            {{ item.message }}
          </li>
        </ul>
      </span>
    </base-alert>
  </div>
</template>
<script lang="ts">
import { Component, PropSync, Watch } from 'vue-property-decorator';
import BaseAlert from './template/BaseAlert.vue';

@Component({
  name: 'return-component',
})
export default class ReturnComponent extends BaseAlert {
  @PropSync('httpResponse')
  public syncedHttpResponse!: object;

  public httpStatus: boolean = false;
  public msgType: string = '';
  public msg: any = {};
  public msgStr: string = '';
  public isMsgStr: boolean = false;

  public async handleReturn(): Promise<void> {
    const ret: any = this.syncedHttpResponse;

    if ((ret && ret.status === 400) || (ret && ret.status === 401)) {
      this.httpStatus = true;
      this.msgType = 'danger';

      if (typeof ret.data !== 'undefined') {
        if (typeof ret.data.errors !== 'undefined') {
          const msg = ret.data.errors;
          this.isMsgStr = false;

          if (typeof msg === 'object') {
            this.msg = msg;
          } else {
            this.isMsgStr = true;
            this.msgStr = msg;
          }
        } else {
          this.isMsgStr = true;
          this.msgStr = ret.data.message;
        }
      } else {
        this.isMsgStr = true;
        this.msgStr = ret.message;
      }
    } else if (ret && ret.status === 200) {
      this.httpStatus = true;
      this.msgType = 'success';
      this.isMsgStr = true;
      this.msgStr = ret.message;
    }
  }

  @Watch('syncedHttpResponse')
  public onHttpResponseChanged(val: object): void {
    this.syncedHttpResponse = val;
    this.handleReturn();
  }

  public updateVisible(status: boolean): void {
    this.httpStatus = status;
  }
}
</script>
