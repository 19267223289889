import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { IScheduleState, Schedule, ScheduleTests } from './types';

type ScheduleGetterTree = GetterTree<IScheduleState, RootState>;

export const getters: ScheduleGetterTree = {
  getSchedule(state: IScheduleState): Schedule | null {
    return state.schedule;
  },

  getScheduleTests(state: IScheduleState): ScheduleTests | null {
    return state.tests;
  },
};
