import type { MutationTree } from 'vuex';
import type { IAnnotation, IAnnotations, IToolsState } from './types';

type ToolsMutationsTree = MutationTree<IToolsState>;

export const mutations: ToolsMutationsTree = {
  setAnnotations(state: IToolsState, payload: IAnnotations): void {
    state.annotations = payload;
  },

  setAnnotation(state: IToolsState, payload: IAnnotation): void {
    const pl = JSON.parse(JSON.stringify(payload));
    state.annotations = state.annotations?.filter((annotation) => {
      return annotation.id !== pl.id;
    });

    state.annotations?.push(pl);
  },

  deleteAnnotation(state: IToolsState, payload: string): void {
    state.annotations = state.annotations?.filter((annotation) => annotation.id !== payload);
  },
};
