import type { MutationTree } from 'vuex';
import type { IScheduleState, Schedule, ScheduleTests } from './types';

type ScheduleMutationsTree = MutationTree<IScheduleState>;

export const mutations: ScheduleMutationsTree = {
  setSchedule(state: IScheduleState, payload: Schedule): void {
    state.schedule = payload;
  },

  setScheduleTests(state: IScheduleState, payload: ScheduleTests): void {
    state.tests = payload;
  },
};
