import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

type Options = { progress?: boolean; canIsLoading?: boolean };

@Component
export default class LoadingMixin extends Vue {
  @Action('setLoading')
  public setLoading!: (v: boolean) => void;

  @Getter('isLoading')
  public overlayLoader!: boolean;

  public isLoading = false;

  protected httpReturnedObj: Record<string, any> = {};

  public get httpReturn() {
    return this.httpReturnedObj;
  }

  public get loadingStatus() {
    return this.isLoading;
  }

  public async runLoad(
    fn: () => Promise<void>,
    { progress = true, canIsLoading = false }: Options = {}
  ): Promise<void> {
    this.isLoading = canIsLoading;

    try {
      if (!this.overlayLoader && progress) {
        this.$Progress?.start();
      }

      await fn();

      if (!this.overlayLoader && progress) {
        this.$Progress?.finish();
      }
    } catch (e) {
      this.$Progress?.fail();
      throw e;
    } finally {
      this.hideOverlay();
      if (progress && this.isLoading) {
        this.$Progress?.finish();
      }
      this.isLoading = false;
    }
  }

  hideOverlay() {
    this.setLoading(false);
  }

  cancelAllLoadings() {
    this.$Progress?.finish();
    this.isLoading = false;
    this.setLoading(false);
  }

  @Getter('activeProduct')
  public activeProduct!: string;
}
