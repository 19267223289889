import ServerError from './ServerError';
import type { FriendlyErrorResponse } from './types';

export default class AuthError extends ServerError {
  constructor(error: FriendlyErrorResponse, message: string = '') {
    super(error, message);

    this.name = 'AuthError';
    this.message = message;

    this.data = error.response?.data;
    this.errors = error.response?.data?.errors;
  }

  public readonly errors: any;
  public readonly data: any;
}
