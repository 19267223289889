import { ApiAbstract } from './ApiCallerAbstract';
import { default as Axios, type AxiosInstance, type AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { getConfig } from './plugins/config';

export class ApiQtaCaller extends ApiAbstract {
  public static getInstance(): ApiQtaCaller {
    if (!ApiQtaCaller.instance) {
      const axios = Axios.create({
        baseURL: getConfig().ApiQTA,
      });

      axiosRetry(axios, {
        retries: 5,
        retryDelay: () => {
          return 5000;
        },
      });

      ApiQtaCaller.instance = new ApiQtaCaller(axios);
    }

    return ApiQtaCaller.instance;
  }

  private static instance: ApiQtaCaller;

  private constructor(axios: AxiosInstance) {
    super(axios);
    const header = 'Authorization';

    axios.interceptors.request.use((config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      if (axios.defaults.headers.common[header]) return Promise.resolve(config);

      const token = localStorage.getItem(getConfig().LocalStorageQTA);
      if (token) {
        ApiQtaCaller.instance.setAuthorizationHeader(token);
        // @ts-ignore
        config.headers.common[header] = token;
      }

      return Promise.resolve(config);
    });
  }

  public setAuthorizationHeader(token: string): void {
    super.setAuthorizationHeader(token);
    localStorage.setItem(getConfig().LocalStorageQTA, token);
  }

  public clearAuthorizationHeader(): void {
    super.clearAuthorizationHeader();
    localStorage.removeItem(getConfig().LocalStorageQTA);
  }
}

export default ApiQtaCaller.getInstance();
