import type { ActionContext, ActionTree } from 'vuex';
import type { IVocabularyBuilderState } from '@/store/modules/vocabulary-builder/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type VocabularyBuilderActionContext = ActionContext<IVocabularyBuilderState, RootState>;
type VocabularyBuilderActionTree = ActionTree<IVocabularyBuilderState, RootState>;

export const actions: VocabularyBuilderActionTree = {
  async fetchWords(context: VocabularyBuilderActionContext): Promise<any> {
    const words = await ApiCaller.getInstance().get(`wordsmith/words`);

    context.commit('setWords', words.data);
  },

  async setSort(_context: VocabularyBuilderActionContext, payload: { word_id: number; sort: string }): Promise<any> {
    await ApiCaller.getInstance().post('wordsmith/sort/', payload);
  },

  async fetchReview(context: VocabularyBuilderActionContext): Promise<any> {
    await context.dispatch('mountQuery', 'review');
    const query = context.state.filter.query;
    const review = await ApiCaller.getInstance().get('wordsmith/review' + query);

    context.commit('setReview', review.data);
  },

  async fetchTest(context: VocabularyBuilderActionContext): Promise<any> {
    await context.dispatch('mountQuery', 'test');
    const query = context.state.filter.query;
    const test = await ApiCaller.getInstance().get('wordsmith/start-test' + query);

    context.commit('setTest', test.data);
  },

  async finishTest(context: VocabularyBuilderActionContext, payload: any): Promise<any> {
    const finish = await ApiCaller.getInstance().post('wordsmith/finish-test/', payload);

    context.commit('setTestReview', finish.data);
  },

  async fetchCount(context: VocabularyBuilderActionContext): Promise<any> {
    await context.dispatch('mountQuery', 'count');
    const query = context.state.filter.query;
    const count = await ApiCaller.getInstance().get('wordsmith/count' + query);
    context.commit('setCount', count.data);
  },

  async fetchWordOfTheDay(context: VocabularyBuilderActionContext): Promise<any> {
    const word = await ApiCaller.getInstance().get('wordsmith/word-of-the-day');
    context.commit('setWordOfTheDay', word.data);
  },

  mountQuery(context: VocabularyBuilderActionContext, type: string): Promise<void> {
    const filter = context.state.filter;
    let query_string = '';
    const term_count = filter.term_count;
    let query = type === 'count' ? '?' : '?limit=' + term_count;

    filter.levels.forEach(function (level: any) {
      query_string += '&levels=' + level;
    });

    filter.sorts.forEach(function (sort: any) {
      query_string += '&sorts=' + sort;
    });

    if (type === 'count' || type === 'test') {
      filter.question_types.forEach(function (question_type: any) {
        query_string += '&types=' + question_type;
      });
    }

    query += type === 'count' ? query_string.substring(1) : query_string;

    context.commit('setQuery', query);

    return Promise.resolve();
  },
};
