import type { MutationTree } from 'vuex';
import type {
  IScoreProgressionState,
  IScoreProgressionChart,
  IScoreProgressionTable,
} from '@/store/modules/report/score-progression/types';

type ScoreProgressionTree = MutationTree<IScoreProgressionState>;

export const mutations: ScoreProgressionTree = {
  setChart(state: IScoreProgressionState, chart: IScoreProgressionChart) {
    state.chart = chart;
  },
  setTable(state: IScoreProgressionState, table: IScoreProgressionTable[]) {
    state.table = table;
  },
};
