import type { ActionContext, ActionTree } from 'vuex';
import type { IQuizAnswer, IQuizState } from '@/store/modules/quiz/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type QuizActionContext = ActionContext<IQuizState, RootState>;
type QuizActionTree = ActionTree<IQuizState, RootState>;

export const actions: QuizActionTree = {
  async fetchQuizzes(context: QuizActionContext): Promise<any> {
    const quizzes = await ApiCaller.getInstance().get(`${context.rootState.productPrefix}quizzes/available`);
    context.commit('setQuizzes', quizzes.data);
  },

  async fetchLessons(context: QuizActionContext): Promise<any> {
    const lessons = await ApiCaller.getInstance().get(`${context.rootState.productPrefix}quizzes/lessons`);
    context.commit('setLessons', lessons.data);
  },

  async fetchRelevance(context: QuizActionContext, filter: any): Promise<any> {
    const relevance =
      Object.keys(filter).length > 0 && filter.lesson_id !== 'all'
        ? await ApiCaller.getInstance().get(
            `${context.rootState.productPrefix}quizzes/relevance/${filter.subject}/${filter.lesson_id}`
          )
        : await ApiCaller.getInstance().get(`${context.rootState.productPrefix}quizzes/relevance`);

    context.commit('setRelevance', relevance.data);
  },

  async fetchCorrectIncorrect(context: QuizActionContext, filter: any): Promise<any> {
    const correctIncorrect =
      Object.keys(filter).length > 0 && filter.lesson_id !== 'all'
        ? await ApiCaller.getInstance().get(
            `${context.rootState.productPrefix}quizzes/correct-incorrect/${filter.subject}/${filter.lesson_id}`
          )
        : await ApiCaller.getInstance().get(`${context.rootState.productPrefix}quizzes/correct-incorrect`);

    context.commit('setCorrectIncorrect', correctIncorrect.data);
  },

  async fetchPctReview(context: QuizActionContext, usection_id: number): Promise<any> {
    const pctReviewed = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}quizzes/pct-reviewed/${usection_id}`
    );

    context.commit('setPctReviewed', pctReviewed.data);
  },

  async takeSection(context: QuizActionContext, params: any): Promise<any> {
    const content = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}quizzes/take-section/${params.subject}/${params.testNumber}`
    );
    context.commit('setSection', content.data);
  },

  async fetchAnsweredQuestions(context: QuizActionContext, params: any): Promise<any> {
    const content = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}quizzes/review-questions/${params.subject}/${params.testNumber}`
    );
    context.commit('setAnsweredQuestions', content.data);
  },

  async reviewQuestion(context: QuizActionContext, payload: {}): Promise<any> {
    await ApiCaller.getInstance().post(`${context.rootState.productPrefix}quizzes/question-viewed`, payload);
  },

  async saveAnswer(context: QuizActionContext, payload: IQuizAnswer): Promise<any> {
    await ApiCaller.getInstance().post(`${context.rootState.productPrefix}quizzes/save-answer`, payload);
  },

  async finishSection(context: QuizActionContext, usectionId: number): Promise<any> {
    await ApiCaller.getInstance().post(`${context.rootState.productPrefix}quizzes/finish-section`, {
      usection_id: usectionId,
    });
  },
};
