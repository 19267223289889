import type { Module } from 'vuex';
import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';
import type { IAuthState } from '@/store/modules/auth/types';
import type { RootState } from '@/store/types';

const namespaced: boolean = true;

type AuthModule = Module<IAuthState, RootState>;

export const authModule: AuthModule = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
