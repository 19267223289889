<template>
  <transition name="fade" mode="out-in">
    <div
      class="alert"
      :class="[`alert-${type}`, { 'alert-dismissible': dismissible }]"
      role="alert"
      v-if="syncedVisible"
    >
      <slot v-if="!dismissible">
        <span v-if="icon" class="alert-inner--icon">
          <i :class="icon"></i>
        </span>
        <span v-if="$slots.text" class="alert-inner--text">
          <slot name="text"></slot>
        </span>
      </slot>
      <template v-else>
        <slot>
          <span v-if="icon" class="alert-inner--icon">
            <i :class="icon"></i>
          </span>
          <span v-if="$slots.text" class="alert-inner--text">
            <slot name="text"></slot>
          </span>
        </slot>
        <slot name="dismiss-icon">
          <button type="button" data-dismiss="alert" aria-label="Close" class="close" @click="dismissAlert">
            <span aria-hidden="true">×</span>
          </button>
        </slot>
      </template>
    </div>
  </transition>
</template>
<style>
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-leave-active {
  transition: opacity 1s;
  opacity: 0;
}
</style>
<script lang="ts">
import { Component, Emit, PropSync, Vue } from 'vue-property-decorator';

@Component({
  name: 'base-alert',
})
export default class BaseAlert extends Vue {
  @PropSync('type', { type: String, default: 'default' })
  public syncedType!: string; // Alert type

  @PropSync('icon', { type: String, default: '' })
  public syncedIcon!: string; // Alert icon. Will be overwritten by default slot

  @PropSync('dismissible', { type: Boolean, default: false })
  public syncedDismissible!: boolean; // Whether alert is closes when clicking

  @PropSync('visible', { type: Boolean, default: false })
  public syncedVisible!: boolean;

  @Emit('update:visible')
  public dismissAlert() {
    return false;
  }
}
</script>
