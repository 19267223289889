import type { GetterTree } from 'vuex';
import type { ActivityData, IActivityReportState } from '@/store/modules/report/activity-report/types';
import type { RootState } from '@/store/types';

type ActivityReportGetter = GetterTree<IActivityReportState, RootState>;

export const getters: ActivityReportGetter = {
  getActivity(state: IActivityReportState): ActivityData | null {
    const { activity } = state;
    return activity;
  },
};
