import type { GetterTree } from 'vuex';
import type { IAssessmentState as AssessmentState, IAssignment, IPerformance } from '@/store/modules/assessment/types';
import type { RootState, IUSection } from '@/store/types';

type AssessmentGetterTree = GetterTree<AssessmentState, RootState>;

export const getters: AssessmentGetterTree = {
  getSection(state: AssessmentState): IUSection | null {
    return state.section || null;
  },

  getPerformance(state: AssessmentState): IPerformance | null {
    return state.performance || null;
  },

  getAssignments(state: AssessmentState): IAssignment[] {
    return state.assignments || [];
  },

  getSections(state: AssessmentState): IUSection[] | [] {
    return state.sections || [];
  },

  sectionTime(state: AssessmentState): number {
    const { section } = state;
    return (section && section.time) || 0;
  },

  sectionCompleted(state: AssessmentState): boolean {
    const { section } = state;
    return !!section?.is_completed;
  },

  sectionForcedFinished(state: AssessmentState): boolean {
    const { section } = state;
    return !!section?.is_force_finished;
  },

  isOngoingSection(state: AssessmentState): boolean {
    const { section } = state;
    if (section && section.answers) {
      return Object.keys(section.answers).length > 0;
    }

    return false;
  },
};
