<template>
  <component :is="tag" :type="tag === 'button' ? nativeType : ''" @click="handleClick" class="btn" :class="classes">
    <span class="btn-inner--icon" v-if="$slots.icon || (icon && $slots.default)">
      <slot name="icon">
        <i :class="icon"></i>
      </slot>
    </span>
    <i v-if="!$slots.default" :class="icon"></i>
    <span class="btn-inner--text" v-if="$slots.icon || (icon && $slots.default)">
      <slot>
        {{ text }}
      </slot>
    </span>
    <slot v-if="!$slots.icon && !icon"></slot>
  </component>
</template>
<script lang="ts">
import { Component, Emit, PropSync, Vue } from 'vue-property-decorator';

@Component({
  name: 'base-button',
})
export default class BaseButton extends Vue {
  // Button tag (default -> button)
  @PropSync('tag', { type: String, default: 'button' })
  public syncedTag!: string;

  // Button type (e,g primary, danger etc)
  @PropSync('type', { type: String, default: 'default' })
  public syncedType!: string;

  // Button size lg|sm
  @PropSync('size', { type: String, default: '' })
  public syncedSize!: string;

  // Button text color (e.g primary, danger etc)
  @PropSync('textColor', { type: String, default: '' })
  public syncedTextColor!: string;

  // Button native type (e.g submit,button etc)
  @PropSync('nativeType', { type: String, default: 'button' })
  public syncedNativeType!: string;

  // Button icon
  @PropSync('icon', { type: String, default: '' })
  public syncedIcon!: string;

  // Button text in case not provided via default slot
  @PropSync('text', { type: String, default: '' })
  public syncedText!: string;

  // Whether button style is outline
  @PropSync('outline', { type: Boolean, default: false })
  public syncedOutline!: boolean;

  // Whether button style is rounded
  @PropSync('rounded', { type: Boolean, default: false })
  public syncedRounded!: boolean;

  // Whether button contains only an icon
  @PropSync('iconOnly', { type: Boolean, default: false })
  public syncedIconOnly!: boolean;

  // Whether button is of block type
  @PropSync('block', { type: Boolean, default: false })
  public syncedBlock!: boolean;

  get classes() {
    const btnClasses = [
      { 'btn-block': this.syncedBlock },
      { 'rounded-circle': this.syncedRounded },
      { 'btn-icon-only': this.syncedIconOnly },
      { [`text-${this.syncedTextColor}`]: this.syncedTextColor },
      { 'btn-icon': this.syncedIcon || this.$slots.icon },
      this.syncedType && !this.syncedOutline ? `btn-${this.syncedType}` : '',
      this.syncedOutline ? `btn-outline-${this.syncedType}` : '',
    ];
    if (this.syncedSize) {
      btnClasses.push(`btn-${this.syncedSize}`);
    }
    return btnClasses;
  }

  @Emit('button:click')
  public handleClick(evt: any) {
    if (evt) {
      return evt;
    }
  }
}
</script>
