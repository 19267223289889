import type { ActionContext, ActionTree } from 'vuex';
import type { RootState, Token } from '@/store/types';
import type {
  IAnswer,
  IAssignmentState,
  IStudentAssignments,
  IStudentContent,
  AssignmetUUID,
  TestTakePayload,
  SaveAnswerPayload,
} from './types';
import apiCsi from '@/ApiCaller';
import apiQta from '@/ApiQtaCaller';
import { getConfig } from '@/plugins/config';

type AssignmentActionContext = ActionContext<IAssignmentState, RootState>;
type AssignmentActionTree = ActionTree<IAssignmentState, RootState>;

type QtaToken = {
  key: string;
  uuid: string;
};

export const actions: AssignmentActionTree = {
  /**
   * QTA SSO Login
   *
   * @async
   * @param {AssignmentActionContext} _ - [TODO:description]
   * @returns {Promise<void>} [TODO:description]
   */
  async login(_: AssignmentActionContext): Promise<void> {
    const token = localStorage.getItem(getConfig().LocalStorageQTA);

    if (token) return;

    const { data: qtaToken } = await apiCsi.get<QtaToken>('auth/qta-token');
    const { data } = await apiQta.post<Token, QtaToken>('auth/sso-login', qtaToken);

    apiQta.setAuthorizationHeader(data.token);
  },

  /**
   * QTA SSO Logout
   */
  logout(): void {
    apiQta.clearAuthorizationHeader();
  },

  /**
   * Fetch student assignments schedule
   *
   * @async
   * @param {AssignmentActionContext} context - vuex module context
   * @returns {Promise<void>}
   */
  async fetchSchedule({ commit, rootGetters }: AssignmentActionContext): Promise<void> {
    const { start_at, end_at } = rootGetters['authModule/period'] || {};

    if (start_at && end_at) {
      const { data } = await apiQta.get<IStudentAssignments[]>(`schedule/from/${start_at}/to/${end_at}`);
      commit('setSchedule', data);
    } else {
      commit('setSchedule', []);
    }
  },

  /**
   * Fetch schedule content
   *
   * @async
   * @param {AssignmentActionContext} context - Assignment module context
   * @param {string} payload - content uuid
   * @returns {Promise<void>}
   */
  async fetchContent(context: AssignmentActionContext, payload: TestTakePayload): Promise<void> {
    const { content_uuid, assignment_uuid } = payload;
    const url = `/student/content/${content_uuid}/assignment/${assignment_uuid}`;
    const { data } = await apiQta.get<IStudentContent>(url);
    context.commit('setContent', data);
  },

  async startTest(context: AssignmentActionContext, payload: TestTakePayload): Promise<void> {
    const { content_uuid, assignment_uuid } = payload;
    const url = `/student/content/${content_uuid}/start`;
    const body = { assignment_uuid };

    const { data } = await apiQta.post<IStudentContent, AssignmetUUID>(url, body);

    if (data.answers?.length) {
      context.commit(
        'setAnswers',
        Object.fromEntries(
          data.answers.map((answer) => [answer.question_uuid, { ...answer, skip: answer.ics === 's' }])
        )
      );
    }

    context.commit('setStart');
  },

  async finishTest(context: AssignmentActionContext, payload: TestTakePayload): Promise<void> {
    const { content_uuid, assignment_uuid } = payload;
    const url = `/student/content/${content_uuid}/finish`;
    const body = { assignment_uuid };

    await apiQta.post<IStudentContent, AssignmetUUID>(url, body);

    context.commit('setFinish');
  },

  async saveAnswer(context: AssignmentActionContext, payload: SaveAnswerPayload): Promise<void> {
    const { question_uuid, content_uuid, answer, assignment_uuid } = payload;
    const url = `/student/content/${content_uuid}/assignment/${assignment_uuid}/question/${question_uuid}`;
    const body = {
      time_spent: answer.time_spent,
      answer: answer.answer !== 'SKIP' ? answer.answer : '',
      skip: answer.skip,
    };

    await apiQta.post<IStudentContent, Pick<IAnswer, 'time_spent' | 'answer' | 'skip'>>(url, body);

    context.commit('setAnswer', { ...answer, question_uuid });
  },

  async clearContent(context: AssignmentActionContext): Promise<void> {
    context.commit('clearContent');
    return Promise.resolve();
  },
};
