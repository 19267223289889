<template>
  <base-button
    v-bind="{
      tag,
      type,
      size,
      textColor,
      nativeType,
      text,
      outline,
      rounded,
      iconOnly,
      block,
    }"
    :disabled="load"
    :icon="load ? 'fa fa-circle-o-notch fa-spin' : icon"
    :class="load ? 'disabled' : ''"
    @button:click="handleClick"
  >
    <slot>{{ btnLabel }}</slot>
  </base-button>
</template>
<script lang="ts">
import { Component, Prop, PropSync } from 'vue-property-decorator';
import BaseButton from './template/BaseButton.vue';

@Component({
  name: 'button-component',
})
export default class ButtonComponent extends BaseButton {
  @Prop({ default: false })
  public load!: boolean;

  @PropSync('label', { type: String })
  public syncedLabel!: string;

  @PropSync('labelLoading', { type: String, default: 'Loading...' })
  public syncedLabelLoading!: string;

  get btnLabel() {
    let txt = this.syncedLabel;
    if (this.load) {
      txt = this.syncedLabelLoading;
    }
    return txt;
  }
}
</script>
