import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { IProfileMeta, IProfileState } from './types';

type ProfileGetterTree = GetterTree<IProfileState, RootState>;

export const getters: ProfileGetterTree = {
  profileMeta(state: IProfileState): IProfileMeta | null {
    return state.profile_meta;
  },
};
