import type { IFlowState } from './types';

export const state: IFlowState = {
  schedules: null,
  subject: null,
  subject_label: null,
  num: null,
  wait: null,
  step: null,
  etl: null,
};
