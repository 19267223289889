<template>
  <transition name="fade" mode="out-in" appear class="default-transition">
    <header id="header" class="header-global" ref="headerContainer">
      <base-nav class="navbar-main fixed-top" type="default" effect="dark" expand>
        <div class="row" slot="content-header" slot-scope="{ closeMenu }">
          <div class="col-6 collapse-brand">
            <router-link to="/" class="logo-switcher">
              <img :src="'/themes/' + theme.name + '/logo-switcher.png'" :alt="theme.title" />
            </router-link>
          </div>
          <div class="col-6 collapse-close">
            <close-button @click="closeMenu"></close-button>
          </div>
        </div>

        <ul class="navbar-nav navbar-nav-hover align-items-lg-center" id="product-menu">
          <li v-if="!productDropdownActive" class="nav-item product-switcher">
            <a slot="title" href="#" class="nav-link p-2 text-center" data-toggle="dropdown" role="button">
              <img class="logo-header" :src="'/themes/' + theme.name + '/logo-header.png'" :alt="theme.title" />

              <span class="product-switcher-current">
                {{ productName(activeProduct) }}
              </span>
            </a>
          </li>
          <base-dropdown v-else tag="li" class="nav-item product-switcher">
            <a slot="title" href="#" class="nav-link p-2 text-center" data-toggle="dropdown" role="button">
              <img class="logo-header" :src="'/themes/' + theme.name + '/logo-header.png'" :alt="theme.title" />

              <span class="product-switcher-current">
                {{ productName(activeProduct) }}
                <i class="fa fa-angle-down"></i>
              </span>
            </a>

            <template v-if="productsDropdown?.length">
              <template v-for="product in productsDropdown">
                <a
                  @click.prevent="switchProduct(product.product_type.toLowerCase(), product)"
                  type="button"
                  class="dropdown-item"
                  v-if="activeProduct !== product.product_type.toLowerCase()"
                >
                  <img
                    class="product-switcher-item-logo logo-switcher"
                    :src="'/themes/' + theme.name + '/logo-switcher.png'"
                    :alt="theme.title"
                  />

                  <span> {{ productName(product.product_type.toLowerCase()) }} </span>

                  <p
                    v-if="isCert() && productDescription(product.product_type.toLowerCase())"
                    class="text-muted small product-description mb-0"
                  >
                    {{ productDescription(product.product_type.toLowerCase()) }}
                  </p>
                </a>
              </template>
            </template>
          </base-dropdown>

          <template v-if="checkDefaultMenu">
            <li v-if="hasHomeRoom || isDemo">
              <router-link
                slot="title"
                class="nav-link p-2 ml-1 mr-1"
                :to="isDemo ? '/practice/home-room' : '/home-room'"
              >
                <span class="nav-link-inner--text">Home Room</span>
              </router-link>
            </li>

            <li v-if="hasExamRoom">
              <router-link
                slot="title"
                class="nav-link p-2 ml-1 mr-1"
                to="/exam-room"
                :class="currentPage.includes('assessment') && !currentPage.includes('tsi') ? activeClass : ''"
              >
                <span class="nav-link-inner--text">Exam Room</span>
              </router-link>
            </li>

            <li v-if="hasStudyHall">
              <router-link slot="title" class="nav-link p-2 ml-1 mr-1" to="/study-hall">
                <span class="nav-link-inner--text">Study Hall</span>
              </router-link>
            </li>

            <li v-if="hasQuizzes">
              <router-link
                slot="title"
                class="nav-link p-2 ml-1 mr-1"
                to="/quizzes"
                :class="currentPage.includes('quiz') ? activeClass : ''"
              >
                <span class="nav-link-inner--text">Quizzes</span>
              </router-link>
            </li>

            <li v-if="hasVb">
              <router-link
                class="nav-link p-2 ml-1 mr-1"
                :to="`/${vbOrWordsmithSlug()}/sort`"
                :class="currentPage.includes(vbOrWordsmithSlug()) ? activeClass : ''"
              >
                <span class="nav-link-inner--text">{{ vbOrWordsmithLabel() }}</span>
              </router-link>
            </li>
          </template>

          <template v-if="hasGraduationPlanner && activeProduct === 'gp'">
            <li>
              <router-link slot="title" class="nav-link p-2 ml-1 mr-1" to="/graduation-planner/start">
                <span class="nav-link-inner--text">Student</span>
              </router-link>
            </li>
            <li>
              <router-link slot="title" class="nav-link p-2" to="/graduation-planner/colleges">
                <span class="nav-link-inner--text">Colleges</span>
              </router-link>
            </li>

            <li>
              <router-link slot="title" class="nav-link p-2" to="/graduation-planner/fitmatch">
                <span class="nav-link-inner--text">Fit &amp; Match</span>
              </router-link>
            </li>

            <li>
              <router-link slot="title" class="nav-link p-2" to="/graduation-planner/cost-aid">
                <span class="nav-link-inner--text">Cost &amp; Aid</span>
              </router-link>
            </li>
          </template>

          <template v-if="!account && !isDemo">
            <li>
              <router-link slot="title" class="nav-link p-2" to="/about/system-requirements">
                <span class="nav-link-inner--text">System Requirements</span>
              </router-link>
            </li>
            <li>
              <router-link slot="title" class="nav-link p-2" to="/about/privacy-policy">
                <span class="nav-link-inner--text">Privacy Policy</span>
              </router-link>
            </li>
            <li>
              <router-link slot="title" class="nav-link p-2" to="/login">
                <span class="nav-link-inner--text">Back to login page</span>
              </router-link>
            </li>
          </template>

          <template v-if="hasTSI && activeProduct === 'tsi'">
            <li>
              <router-link
                class="nav-link p-2 ml-1 mr-1"
                to="/sections"
                :class="currentPage.includes('sections') ? activeClass : ''"
              >
                <span class="nav-link-inner--text">Sections</span>
              </router-link>
            </li>

            <li v-if="hasStudyHall">
              <router-link
                class="nav-link p-2 ml-1 mr-1"
                to="/study-hall"
                :class="currentPage.includes('study-hall') ? activeClass : ''"
              >
                <span class="nav-link-inner--text">Study Hall</span>
              </router-link>
            </li>

            <li v-if="hasVb">
              <router-link
                class="nav-link p-2 ml-1 mr-1"
                :to="`/${vbOrWordsmithSlug()}/sort`"
                :class="currentPage.includes(vbOrWordsmithSlug()) ? activeClass : ''"
              >
                <span class="nav-link-inner--text">{{ vbOrWordsmithLabel() }}</span>
              </router-link>
            </li>
          </template>

          <template v-if="hasKSA && activeProduct === 'ksa'">
            <li>
              <router-link
                class="nav-link p-2 ml-1 mr-1"
                to="/home-room"
                :class="currentPage.includes('/home-room') ? activeClass : ''"
              >
                <span class="nav-link-inner--text">Home Room</span>
              </router-link>
            </li>
          </template>

          <template v-if="hasFSA && activeProduct === 'fsa'">
            <li>
              <router-link
                class="nav-link p-2 ml-1 mr-1"
                to="/home-room"
                :class="currentPage.includes('/home-room') ? activeClass : ''"
              >
                <span class="nav-link-inner--text">Home Room</span>
              </router-link>
            </li>
          </template>

          <template v-if="hasReact && activeProduct === 'react'">
            <li>
              <router-link
                class="nav-link p-2 ml-1 mr-1"
                to="/home-room"
                :class="currentPage.includes('/home-room') ? activeClass : ''"
              >
                <span class="nav-link-inner--text">Home Room</span>
              </router-link>
            </li>
          </template>

          <template v-if="activeProduct === 'qta'">
            <li>
              <router-link
                class="nav-link p-2 ml-1 mr-1"
                to="/assignments"
                :class="currentPage.includes('assignment') ? activeClass : ''"
              >
                <span class="nav-link-inner--text">Assignments</span>
              </router-link>
            </li>
          </template>
        </ul>

        <!-- logout and profiles -->
        <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto nav-bar-extra">
          <base-dropdown tag="li" class="nav-item">
            <!-- return to dashboard -->
            <template v-if="this.permissions?.dashboard">
              <a class="dropdown-item" :href="theme.legacyAddress + '/dashboard/students'">Return to Dashboard</a>
              <div class="dropdown-divider"></div>
            </template>

            <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
              <i class="fa fa-user-circle mr-2"></i>
              <span class="nav-link-inner--text">
                {{ isDemo ? 'Demo Student' : account.name }}
              </span>
              <i class="fa fa-angle-down"></i>
            </a>
            <router-link v-if="!isDemo && !isETL" class="dropdown-item" to="/profile">
              <span class="nav-link-inner--text">Profile</span>
            </router-link>

            <!-- TODO: re-add reports button later -->
            <router-link v-if="!isCert()" class="dropdown-item" to="/reports">
              <span class="nav-link-inner--text">Reports</span>
            </router-link>

            <router-link class="dropdown-item" to="/logout">
              <span class="nav-link-inner--text">Logout</span>
            </router-link>
          </base-dropdown>
        </ul>
      </base-nav>
    </header>
  </transition>
</template>
<script lang="ts">
import { Component, Prop, Watch } from 'vue-property-decorator';
import BaseNav from '@/components/base/template/vue-argon-design-template/BaseNav.vue';
import BaseDropdown from '@/components/base/template/vue-argon-design-template/BaseDropdown.vue';
import CloseButton from '@/components/base/template/vue-argon-design-template/CloseButton.vue';
import { Action, Getter } from 'vuex-class';
import type { IPermissions, IProduct, IUser } from '@/store/modules/auth/types';
import { getConfig, type ThemeConfig } from '@/plugins/config';
import router from '@/router';
import {
  getProductDescription,
  getProductName,
  getProductSlug,
  isCert,
  isDsatOrDpsat,
  removeDiagWhenFull,
} from '@/helpers';
import { vbOrWordsmithLabel, vbOrWordsmithSlug } from '@/utils';
import { mixins } from 'vue-class-component';
import LoadingMixin from '@/mixins/LoadingMixin';

const namespace = 'authModule';

@Component({
  name: 'app-header',
  methods: { isCert, vbOrWordsmithSlug, vbOrWordsmithLabel },
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
})
export default class AppHeader extends mixins(LoadingMixin) {
  @Prop({ default: false })
  public isDemo!: boolean;

  @Getter('theme')
  public theme!: ThemeConfig;

  @Getter('hasExamRoom', { namespace })
  public hasExamRoom!: boolean;

  @Getter('hasHomeRoom', { namespace })
  public hasHomeRoom!: boolean;

  @Getter('hasStudyHall', { namespace })
  public hasStudyHall!: boolean;

  @Getter('hasQuizzes', { namespace })
  public hasQuizzes!: boolean;

  @Getter('hasTSI', { namespace })
  public hasTSI!: boolean;

  @Getter('hasDashboard', { namespace })
  public hasDashboard!: boolean;

  @Getter('hasVb', { namespace })
  public hasVb!: boolean;

  @Getter('hasSat', { namespace })
  public hasSat!: boolean;

  @Getter('hasKSA', { namespace })
  public hasKSA!: boolean;

  @Getter('hasFSA', { namespace })
  public hasFSA!: boolean;

  @Getter('hasReact', { namespace })
  public hasReact!: boolean;

  @Getter('hasGraduationPlanner', { namespace })
  public hasGraduationPlanner!: boolean;

  @Getter('isETL', { namespace })
  public isETL!: boolean;

  @Getter('account', { namespace })
  public account!: IUser;

  @Getter('getReferrerURL', { namespace })
  public getReferrerURL!: string;

  @Getter('permissions', { namespace })
  public permissions!: IPermissions;

  @Action('setProduct')
  public setProduct!: (payload: string) => Promise<void>;

  @Action('legacySso', { namespace })
  public legacySso!: ({ unqid: string, customUrl: string }) => Promise<any>;

  @Action('switchCourse', { namespace })
  public switchCourse!: (unqid: string) => Promise<any>;

  private activeClass = 'router-link-active';

  productName(product: string) {
    return getProductName(product);
  }

  productDescription(product: string) {
    return getProductDescription(product);
  }

  @Watch('account', { immediate: true, deep: true })
  public onAccountChanged(val: IUser): void {
    if (val && val.activeProduct) {
      this.setProduct(val.activeProduct.product_type.toLowerCase());
    }
  }

  get productsDropdown() {
    const order = ['act', 'dsat', 'sat', 'tsi', 'fsa', 'ksa', 'gp'];
    const products = removeDiagWhenFull(this.account?.otherProducts)?.map(function (p) {
      p.product_type = p.product_type.toLowerCase();
      return p;
    });

    const productsToFilter =
      products?.filter((p) => {
        return order.includes(p.product_type);
      }) || [];

    const productsToNotFilter =
      products?.filter((p) => {
        return !order.includes(p.product_type);
      }) || [];

    const sortProducts = (productsArray: IProduct[], sortArray: string[]) => {
      return [...productsArray].sort(
        (a, b) => sortArray.indexOf(getProductSlug(a.product_type)) - sortArray.indexOf(getProductSlug(b.product_type))
      );
    };

    const sortedProducts = sortProducts(productsToFilter || [], order);

    return [...sortedProducts, ...productsToNotFilter];
  }

  public activeProductObj: IProduct | null = null;

  async switchProduct(productStr: string, productObj?: IProduct) {
    if (productObj) {
      this.activeProductObj = productObj;
      await this.goToProductHome(this.activeProductObj.product_type.toLowerCase());
    } else {
      await this.goToProductHome(productStr);
    }
  }

  async goToProductHome(product: string) {
    const CertThemes = ['cert', 'cpa'];

    this.$Progress.start();
    this.setLoading(true);

    if (!CertThemes.includes(this.theme.name) && !isDsatOrDpsat(product) && this.activeProductObj) {
      const payload = {
        unqid: this.activeProductObj.unqid.toLowerCase(),
        customUrl: this.theme.legacyAddress,
      };

      if (this.account.demo_created_by === getConfig().SystemUid && this.theme.legacyAddressDemo) {
        payload.customUrl = this.theme.legacyAddressDemo;
      }

      const legacyResponse = await this.legacySso(payload);

      if (legacyResponse) {
        window.location.href = legacyResponse.url;
      } else {
        this.$Progress.fail();
      }
    } else if (this.activeProductObj && isDsatOrDpsat(product)) {
      await this.switchCourse(this.activeProductObj.unqid.toLowerCase());
      await this.setProduct(product);

      this.setLoading(false);

      if (router.currentRoute.path !== '/home-room') {
        await router.push('/home-room');
      } else {
        await this.$router.go(0);
      }
    } else {
      if (this.activeProductObj) {
        await this.switchCourse(this.activeProductObj.unqid.toLowerCase());
      }
      await this.setProduct(product);

      this.setLoading(false);

      switch (product) {
        case 'qta':
          if (router.currentRoute.path !== '/assignments') {
            await router.push('/assignments');
          } else {
            await this.$router.go(0);
          }
          break;
        case 'gp':
          if (router.currentRoute.path !== '/graduation-planner/start') {
            await router.push('/graduation-planner/start');
          } else {
            await this.$router.go(0);
          }
          break;
        case 'tsi':
          if (router.currentRoute.path !== '/sections') {
            await router.push('/sections');
          } else {
            await this.$router.go(0);
          }
          break;
        case 'act':
        case 'sat':
        case 'dsat':
        case 'dpsat':
        case 'preact':
        default:
          if (router.currentRoute.path !== '/home-room') {
            await router.push('/home-room');
          } else {
            await this.$router.go(0);
          }
          break;
      }

      this.$Progress.finish();
      this.setLoading(false);
    }
  }

  get currentPage() {
    return this.$route.path;
  }

  get checkDefaultMenu() {
    const allowedProducts = ['act', 'sat', 'dsat', 'dpsat', 'preact'];
    return allowedProducts.includes(this.activeProduct);
  }

  get productDropdownActive() {
    return !(this.currentPage.match(/assessment|quiz\/|session\/|tsi\/assessment|tsi\/essay/gi) || this.isETL);
  }
}
</script>
