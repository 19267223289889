import type { ActionContext, ActionTree } from 'vuex';
import type { IReportsLinksState } from '@/store/modules/report/reports-links/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type ReportsLinksContext = ActionContext<IReportsLinksState, RootState>;
type ReportsLinksTree = ActionTree<IReportsLinksState, RootState>;

export const actions: ReportsLinksTree = {
  async fetchReportsLinks(context: ReportsLinksContext): Promise<any> {
    const report = await ApiCaller.getInstance().get(`${context.rootState.productPrefix}homeroom/reports-links`);
    context.commit('setReportsLinks', report.data);
  },
};
