import type { IQuizState } from '@/store/modules/quiz/types';

export const state: IQuizState = {
  sections: [],
  lessons: [],
  relevance: [],
  correctIncorrect: [],
  section: null,
  answeredQuestions: null,
  pctReview: null,
};
