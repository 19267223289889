import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { IPeriods, ISectionState } from '@/store/modules/section/types';

type AuthGetterTree = GetterTree<ISectionState, RootState>;

export const getters: AuthGetterTree = {
  periods(state: ISectionState): IPeriods {
    if (state.periods) {
      return state.periods;
    }

    return {};
  },
};
