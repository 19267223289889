import ServerError from './ServerError';
import type { FriendlyErrorResponse } from './types';

export default class TokenExpiredError extends ServerError {
  constructor(error: FriendlyErrorResponse) {
    super(error);

    this.name = 'TokenExpiredError';
  }
}
