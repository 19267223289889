import type { MutationTree } from 'vuex';
import type { IProfileState, IProfileAccountInformation, IProfileMeta } from '@/store/modules/profile/types';

type ProfileMutationTree = MutationTree<IProfileState>;

export const mutations: ProfileMutationTree = {
  setProfile(state: IProfileState, profile: IProfileAccountInformation) {
    state.profile = profile;
  },
  setProfileMeta(state: IProfileState, profileMeta: IProfileMeta) {
    state.profile_meta = profileMeta;
  },
};
