import UserFriendlyError from './UserFriendlyError';
import type { ErrorType, IAnswer } from '@/store/types';
import type { FriendlyErrorResponse } from './types';

export default class FinishSectionError extends UserFriendlyError {
  constructor(error: FriendlyErrorResponse, code: ErrorType) {
    super(error, code);

    this.name = 'FinishSectionError';
    this.answers = error.response?.data?.answers || [];
  }

  public readonly answers: IAnswer[];
}
