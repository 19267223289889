<template>
  <loading :active="overlayLoader" :lock-scroll="true" color="#fff" background-color="#ccc" :is-full-page="true" />
</template>
<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import LoadingMixin from '@/mixins/LoadingMixin';

@Component({
  name: 'app-loader',
})
export default class AppLoader extends mixins(LoadingMixin) {}
</script>
