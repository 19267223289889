import type { MutationTree } from 'vuex';
import Vue from 'vue';
import type { IAnswer, IAssignmentState, IStudentAssignments, IStudentContent } from './types';

type AssignmentMutationsTree = MutationTree<IAssignmentState>;

export const mutations: AssignmentMutationsTree = {
  setSchedule(state: IAssignmentState, payload: IStudentAssignments[]): void {
    state.schedule = payload;
  },

  setContent(state: IAssignmentState, payload: IStudentContent): void {
    state.content = payload;
  },

  setAnswers(state: IAssignmentState, payload: Record<string, IAnswer>): void {
    state.answers = payload;
  },

  setAnswer(state: IAssignmentState, payload: IAnswer): void {
    Vue.set(state.answers, payload.question_uuid, payload);
  },

  setStart(state: IAssignmentState): void {
    state.isStart = true;
  },

  setFinish(state: IAssignmentState): void {
    state.isFinish = true;
  },

  clearContent(state: IAssignmentState): void {
    state.answers = {};
    state.content = null;
    state.isFinish = false;
    state.isStart = false;
  },
};
