import type { MutationTree } from 'vuex';
import type {
  LessonPerformanceData,
  ILessonPerformanceReportState,
} from '@/store/modules/report/lesson-performance-report/types';

type LessonPerformanceReportTree = MutationTree<ILessonPerformanceReportState>;

export const mutations: LessonPerformanceReportTree = {
  setLessonPerformance(state: ILessonPerformanceReportState, payload: LessonPerformanceData): void {
    state.lessonPerformance = payload;
  },
};
