<template>
  <div class="h-100">
    <slot></slot>
    <popup-component
      on-popup-close="popup:close"
      @popup:close="onClose"
      :opened="hasError"
      :modal-classes="modalClasses"
      :show-close="false"
      :no-close-on-backdrop="true"
      :title="title"
      :show-cancel="showCancel"
      :cancel-label="closeButtonLabel"
    >
      <span v-html="error"></span>
    </popup-component>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import PopupComponent from '@/components/base/PopupComponent.vue';
import { Action } from 'vuex-class';
import { Config } from '@/plugins/config';
import NotFinishSectionError from '@/errors/NotFinishSectionError';
import IdleError from '@/errors/IdleError';
import TokenExpiredError from '@/errors/TokenExpiredError';
import BetaRedirectionError from '@/errors/BetaRedirectionError';
import NetworkError from '@/errors/NetworkError';
import AuthError from '@/errors/AuthError';
import UserFriendlyError from '@/errors/UserFriendlyError';
import FatalServerError from '@/errors/FatalServerError';
import ServerError from '@/errors/ServerError';
import UnsavedAnswersError from '@/errors/UnsavedAnswersError';
import type { IContentQuestion } from '@/store/modules/content/types';
import WrongAnswersError from '@/errors/WrongAnswersError';
import ModuleOneNotCompletedError from '@/errors/ModuleOneNotCompletedError';
import NotSavedAnswerError from '@/errors/NotSavedAnswerError';

@Component({
  name: 'error-boundary',
  components: {
    PopupComponent,
  },
})
export default class ErrorBoundary extends Vue {
  private onCloseAction: any;
  private errorMessage: string = '';
  public showCancel: boolean = false;
  public title: string = 'An error occurred!';
  public closeButtonLabel: string = 'Close';
  public modalClasses: string = 'modal-danger';
  private fixed: boolean = false;

  public defaultErrorData() {
    this.errorMessage = '';
    this.showCancel = false;
    this.title = 'An error occurred!';
    this.closeButtonLabel = 'Close';
    this.modalClasses = 'modal-danger';
    this.fixed = false;
  }

  @Action('setOnline')
  public setOnline!: (payload: boolean) => Promise<void>;

  @Action('unsetToken', { namespace: 'authModule' })
  public unsetToken!: () => Promise<void>;

  public errorCaptured(error: any /* , vm: Vue, info: string */): boolean {
    this.handleError(error);
    return false;
  }

  public created() {
    window.addEventListener('online', this.showOnline);
    window.addEventListener('offline', this.showOffline);
    window.addEventListener('unhandledrejection', this.handleUncaughtPromise);
  }

  public destroyed() {
    window.removeEventListener('online', this.whatever);
    window.removeEventListener('offline', this.networkError);
    window.removeEventListener('unhandledrejection', this.handleUncaughtPromise);
  }

  private handleUncaughtPromise(event: any) {
    this.handleError(event.reason);
    event.preventDefault();
  }

  private handleError(error: any) {
    this.fixed = !!(error && error.fixed);

    this.notSavedAnswersErrorHandler(error) ||
      this.notFinishSectionErrorHandler(error) ||
      this.notSavedAnswerErrorHandler(error) ||
      this.authError(error) ||
      this.betaAuthError(error) ||
      this.moduleOneNotCompletedErrorHandler(error) ||
      this.notifyError(error) ||
      this.wrongAnswersNotifyError(error) ||
      this.networkError(error) ||
      this.appIdleError(error) ||
      this.appTokenExpiredError(error) ||
      this.appFatalError(error) ||
      this.defaultErrorHandler(error);
  }

  public onClose() {
    this.defaultErrorData();
    if (this.onCloseAction) {
      this.onCloseAction();
    }
  }

  public get hasError(): boolean {
    return !!this.errorMessage;
  }

  public get error(): string {
    return this.errorMessage;
  }

  public whatever() {
    return 'whatever';
  }

  private defaultErrorHandler(error: any): boolean {
    if (Config.Env === 'dev' || Config.Env === 'stage') {
      console.error(error);
    }

    if (!(error instanceof ServerError)) return false;

    this.errorMessage = error.message || 'An unknown error occurred. Check your internet connection and try again.';

    return true;
  }

  private moduleOneNotCompletedErrorHandler(error: unknown): boolean {
    if (!(error instanceof ModuleOneNotCompletedError)) return false;
    this.$router.go(-1);
    return true;
  }

  private notSavedAnswersErrorHandler(error: unknown): boolean {
    if (!(error instanceof UnsavedAnswersError)) return false;

    this.showCancel = true;
    this.closeButtonLabel = 'Logout';
    this.title = 'Because the answers to one or more questions were not saved, this assessment cannot be completed.';
    this.modalClasses = `${this.modalClasses} modal-lg`;
    this.onCloseAction = async () => {
      await this.$router.push('/logout');
    };

    this.errorMessage = this.createQuestionModal(error.questions, error.message);
    return true;
  }

  private notFinishSectionErrorHandler(error: unknown): boolean {
    if (!(error instanceof NotFinishSectionError)) return false;

    this.showCancel = true;
    this.closeButtonLabel = 'Logout';
    this.title = 'A connection error occurred!';
    this.modalClasses = `${this.modalClasses} modal-lg`;
    this.onCloseAction = async () => {
      await this.$router.push('/logout');
    };

    this.errorMessage = this.createQuestionModal(error.questions, error.message);
    return true;
  }

  private notSavedAnswerErrorHandler(error: unknown): boolean {
    if (!(error instanceof NotSavedAnswerError)) return false;
    this.unsetToken();
    this.showCancel = true;
    this.closeButtonLabel = 'Logout';
    this.title = 'A connection error occurred!';
    this.modalClasses = `${this.modalClasses} modal-lg`;
    this.onCloseAction = async () => {
      await this.$router.push('/logout');
    };

    this.errorMessage = this.createQuestionModal(error.questions, error.message);
    return true;
  }

  private handleAnswer(answer: string): string {
    let result;
    try {
      result = JSON.parse(answer);
    } catch (err) {
      result = answer;
    }

    return result instanceof Array ? result.join('/') : answer;
  }

  private createQuestionModal(questions: IContentQuestion[], message: string): string {
    let modal = `
        <div class="row">
          <div class="col-12">
            <div class="row ml-0 mr-0">`;

    questions.forEach((question: any) => {
      modal += `
        <div class="card-question col-lg-1 col-sm-4 col-xs-4 col-s-2">
          <div class="card card-sm bg-white mb-3">
            <div class="card-header text-center text-black-50">
              <b>${question.num}</b>
            </div>
            <div class="card-body text-center">
              <h6 class="card-text">
                ${question.hasOwnProperty('student_answer') ? this.handleAnswer(question.student_answer) : '--'}
              </h6>
            </div>
          </div>
        </div>
        `;
    });

    modal += `
          </div>
        </div>
      </div>
      `;

    modal += `
        <div class="row">
          <div class="col">
            <p class="text-right">${message}</p>
            <p class="text-right"><b>Please write down your answers before logging out.</b></p>
          </div>
        </div>
      `;

    return modal;
  }

  private authError(error: unknown): boolean {
    if (!(error instanceof AuthError)) return false;
    window.location.href = '/logout';

    return true;
  }

  private wrongAnswersNotifyError(error: any): boolean {
    if (!(error instanceof WrongAnswersError)) return false;

    Vue.notify({
      type: 'error',
      duration: 10000,
      group: 'error',
      title: 'An error occurred',
      // @ts-ignore
      ignoreDuplicates: true,
      text: error.message,
    });

    return true;
  }

  private notifyError(error: any): boolean {
    if (!(error instanceof UserFriendlyError)) return false;

    Vue.notify({
      type: 'error',
      duration: 10000,
      group: 'error',
      title: 'An error occurred',
      // @ts-ignore
      ignoreDuplicates: true,
      text: error.message,
    });

    return true;
  }

  private networkError(error: any): boolean {
    if (!(error instanceof NetworkError)) return false;

    Vue.notify({
      type: 'error',
      duration: 10000,
      group: 'error',
      title: 'An error occurred',
      // @ts-ignore
      ignoreDuplicates: true,
      text: error.message,
    });

    return true;
  }

  private appIdleError(e: unknown): boolean {
    if (!(e instanceof IdleError)) return false;
    this.errorMessage = 'You have been inactive for a long time. Please, exit and restart the assessment.';
    this.showCancel = true;

    this.onCloseAction = async () => {
      await this.$router.push('/logout');
    };

    return true;
  }

  private appTokenExpiredError(e: unknown): boolean {
    if (!(e instanceof TokenExpiredError)) return false;
    this.unsetToken();
    this.$router.push('/logout');
    return true;
  }

  private betaAuthError(e: unknown): boolean {
    if (!(e instanceof BetaRedirectionError)) return false;
    window.location.href = e.url;
    return true;
  }

  private showOffline(/* err: any */): void {
    this.setOnline(false);

    this.errorMessage =
      this.$route.name === 'assessment'
        ? ''
        : 'An unknown error occurred. Check your internet connection and try again.';
  }

  private showOnline(/* err: any */): void {
    this.setOnline(true);

    if (!this.fixed) {
      this.errorMessage = '';
    }
  }

  private appFatalError(error: unknown): boolean {
    if (!(error instanceof FatalServerError)) return false;

    this.errorMessage = 'An unknown error occurred. Check your internet connection and try again.';

    return true;
  }
}
</script>
