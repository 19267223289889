import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import AuthError from './errors/AuthError';
import FatalServerError from './errors/FatalServerError';
import createFriendlyError from './errors/UserFriendlyErrorFactory';
import IdleError from './errors/IdleError';
import ServerError from './errors/ServerError';
import BetaRedirectionError from './errors/BetaRedirectionError';
import NetworkError from './errors/NetworkError';
import TokenExpiredError from '@/errors/TokenExpiredError';
import type { FriendlyErrorResponse } from './errors/types';

const parseServerErrorInterceptor = (error: FriendlyErrorResponse) => {
  const { response } = error || {};

  if (response && response.status <= 403) {
    return Promise.reject(new AuthError(error));
  }

  if (response && response.status >= 500) {
    if (response.data && response.data.errMessage && response.data.errMessage === 'jwt expired') {
      return Promise.reject(new TokenExpiredError(error));
    }

    return Promise.reject(new FatalServerError(error));
  }

  if (response && response.status === 401) {
    return Promise.reject(new AuthError(error));
  }

  if (response && response.status === 420) {
    return Promise.reject(createFriendlyError(error));
  }

  if (response && response.status === 428) {
    return Promise.reject(new IdleError(error));
  }

  if (response && response.status === 418) {
    return Promise.reject(new BetaRedirectionError(error));
  }

  if (!error || !error.response) {
    return Promise.reject(new NetworkError(error));
  }

  return Promise.reject(new ServerError(error));
};

const successInterceptor = (response: AxiosResponse) => response;

const header = 'Authorization';

export abstract class ApiAbstract {
  protected constructor(axios: AxiosInstance) {
    this.axios = axios;
    this.axios.interceptors.response.use(successInterceptor, parseServerErrorInterceptor);
  }

  protected axios: AxiosInstance;

  public setAuthorizationHeader(token: string): void {
    if (!token) return;
    this.axios.defaults.headers.common[header] = token;
  }

  public clearAuthorizationHeader(): void {
    delete this.axios.defaults.headers.common[header];
  }

  public del<T, R = AxiosResponse<T>>(url: string): Promise<R> {
    return this.axios.delete(url);
  }

  public put<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.put(url, data, config);
  }

  public patch<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.patch(url, data, config);
  }

  public post<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.post(url, data, config);
  }

  public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.axios.get(url, config);
  }
}
