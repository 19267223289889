import type { MutationTree } from 'vuex';
import type {
  IVocabularyBuilderState,
  IVocabularyBuilderTest,
  IVocabularyBuilderTestReview,
  IVocabularyBuilderWords,
  IWord,
} from '@/store/modules/vocabulary-builder/types';

type VocabularyBuilderMutationsTree = MutationTree<IVocabularyBuilderState>;

export const mutations: VocabularyBuilderMutationsTree = {
  clearState(state: IVocabularyBuilderState, payload: IVocabularyBuilderState): void {
    state.words = payload.words;
  },

  setWords(state: IVocabularyBuilderState, words: IVocabularyBuilderWords[]): void {
    state.words = words;
  },

  setReview(state: IVocabularyBuilderState, review: IWord[]): void {
    state.review = review;
  },

  setTest(state: IVocabularyBuilderState, test: IVocabularyBuilderTest): void {
    state.test = test;
  },

  setTestReview(state: IVocabularyBuilderState, test_review: IVocabularyBuilderTestReview): void {
    state.test_review = test_review;
  },

  setQuery(state: IVocabularyBuilderState, query: string): void {
    state.filter.query = query;
  },

  setCount(state: IVocabularyBuilderState, count: []): void {
    state.filter.count = count;
  },

  setWordOfTheDay(state: IVocabularyBuilderState, word: IWord): void {
    state.word_of_the_day = word;
  },
};
