<template>
  <transition name="fade" mode="out-in">
    <div
      class="modal fade"
      @click.self="closeModalBackDrop()"
      :class="[{ 'show d-block': syncedOpened }, { 'd-none': !syncedOpened }, { 'modal-mini': type === 'mini' }]"
      v-show="syncedOpened"
      tabindex="-1"
      role="dialog"
      :aria-hidden="!syncedOpened"
    >
      <div class="modal-dialog modal-dialog-centered" :class="[{ 'modal-notice': type === 'notice' }, modalClasses]">
        <div class="modal-content" :class="[gradient ? `bg-gradient-${gradient}` : '', modalContentClasses]">
          <div class="modal-header" :class="[headerClasses]" v-if="$slots.header">
            <slot name="header"></slot>
            <slot name="close-button">
              <button
                type="button"
                class="close"
                v-if="showClose"
                @click="closeModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span :aria-hidden="!syncedOpened">×</span>
              </button>
            </slot>
          </div>

          <div class="modal-body" :class="bodyClasses">
            <slot></slot>
          </div>

          <div class="modal-footer" :class="footerClasses">
            <slot name="footer"></slot>
            <button-component
              type="info"
              v-if="showCancel"
              @button:click="closeModal"
              :class="{ disabled: buttonCancelDisable }"
              >{{ cancelLabel }}</button-component
            >
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0.5s;
  opacity: 0;
}

.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<script lang="ts">
import { Component, Prop, PropSync, Watch, Emit, Vue } from 'vue-property-decorator';

@Component({
  name: 'base-modal',
})
export default class BaseModal extends Vue {
  // Disable cancel buttton
  @PropSync('buttonCancelDisable', { type: Boolean, default: false })
  public syncedButtonCancelDisable!: boolean;

  // Shows the X to close the modal
  @PropSync('showClose', { type: Boolean, default: true })
  public syncedShowClose!: boolean;

  // Modal type (notice|mini|"")
  @PropSync('type', { type: String, default: '' })
  public syncedType!: 'notice' | 'mini' | '';

  // Modal dialog css classes
  @PropSync('modalClasses', { type: [Object, String] })
  public syncedModalClasses!: [object, string];

  // Modal dialog content css classes
  @PropSync('modalContentClasses', { type: [Object, String] })
  public syncedModalContentClasses!: [object, string];

  // Modal gradient type (danger, primary etc)
  @PropSync('gradient', { type: String })
  public syncedGradient!: string;

  // Modal Header css classes
  @PropSync('headerClasses', { type: [Object, String] })
  public syncedHeaderClasses!: [object, string];

  // Modal Body css classes
  @PropSync('bodyClasses', { type: [Object, String] })
  public syncedBodyClasses!: [object, string];

  // Modal Footer css classes
  @PropSync('footerClasses', { type: [Object, String] })
  public syncedFooterClasses!: [object, string];

  // Modal opened or not
  @PropSync('opened', { type: Boolean })
  public syncedOpened!: boolean;

  @Prop({ default: true })
  public showCancel!: boolean;

  @Prop({ default: 'Cancel' })
  public cancelLabel!: string;

  @Prop({ default: false })
  public noCloseOnBackdrop!: boolean;

  @Emit('modal:toggle')
  public closeModal() {
    return false;
  }

  @Emit('modal:toggle')
  public openModal() {
    return true;
  }

  public closeModalBackDrop() {
    if (!this.noCloseOnBackdrop) {
      this.closeModal();
    }
  }

  @Watch('opened')
  public onOpenedChanged(val: boolean) {
    const documentClasses = document.body.classList;
    if (val) {
      documentClasses.add('modal-open');
    } else {
      documentClasses.remove('modal-open');
    }
  }
}
</script>
