import type { ActionContext, ActionTree } from 'vuex';
import type { IExamRoomState } from '@/store/modules/examroom/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type ExamRoomActionContext = ActionContext<IExamRoomState, RootState>;
type ExamRoomActionTree = ActionTree<IExamRoomState, RootState>;

export const actions: ExamRoomActionTree = {
  async fetchExamRoom(context: ExamRoomActionContext): Promise<any> {
    const assessments = await ApiCaller.getInstance().get(`${context.rootState.productPrefix}examroom`);

    context.commit('setAssessments', assessments.data);
  },
};
