import { default as Axios, type AxiosInstance, AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import { ApiAbstract } from './ApiCallerAbstract';
import { getConfig } from './plugins/config';

export class ApiReactStudentCaller extends ApiAbstract {
  public static getInstance(): ApiReactStudentCaller {
    if (!ApiReactStudentCaller.instance) {
      const axios = Axios.create({
        baseURL: getConfig().ApiReactStudent,
      });

      axiosRetry(axios, {
        retries: +(import.meta.env.VITE_APP_RETRY_MAX_ATTEMPTS ?? '5'),
        retryDelay: () => {
          return 5000;
        },
        retryCondition: (error: AxiosError) => {
          return (
            (error.response !== undefined &&
              error.response.status !== 400 &&
              error.response.status !== 401 &&
              error.response.status !== 418 &&
              error.response.status !== 420 &&
              error.response.status !== 428 &&
              error.response.status !== 500 &&
              error.response.status !== 504) ||
            error.response === undefined ||
            error.response.status === 500
          );
        },
      });

      ApiReactStudentCaller.instance = new ApiReactStudentCaller(axios);
    }

    return ApiReactStudentCaller.instance;
  }

  private static instance: ApiReactStudentCaller;

  private constructor(axios: AxiosInstance) {
    super(axios);
  }
}

export default ApiReactStudentCaller.getInstance();
