import type { Module } from 'vuex';
import type { ISectionState } from '@/store/modules/section/types';
import type { RootState } from '@/store/types';
import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

const namespaced: boolean = true;

export const sectionModule: Module<ISectionState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
