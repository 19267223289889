import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import type {
  IAnswer,
  IAssignmentState,
  IPassage,
  IPassageQuestion,
  IQuestion,
  IStudentAssignments,
  IStudentContent,
} from './types';

type AssignmentGetterTree = GetterTree<IAssignmentState, RootState>;

export const getters: AssignmentGetterTree = {
  getSchedule(state: IAssignmentState): IStudentAssignments[] {
    return state.schedule;
  },

  getContent(state: IAssignmentState): IStudentContent | null {
    return state.content;
  },

  getQuestions(state: IAssignmentState): IQuestion[] {
    return state.content?.questions?.map((q, idx) => ({ ...q, number: q.number ?? idx + 1 })) ?? [];
  },

  getPassages(state: IAssignmentState): Record<string, IPassage> {
    return (
      state.content?.passages?.reduce((a: Record<string, IPassage>, c: IPassage) => {
        return {
          ...a,
          [c.uuid]: c,
        };
      }, {}) || {}
    );
  },

  getQuestionsPassages(state: IAssignmentState, getter: any): Record<string, IPassage | null> {
    return (
      state.content?.passages_questions?.reduce((a: Record<string, IPassage | null>, c: IPassageQuestion) => {
        return {
          ...a,
          [c.question]: getter.getPassages[c.uuid] ?? null,
        };
      }, {} as Record<string, IPassage | null>) || ({} as Record<string, IPassage | null>)
    );
  },

  getAnswers(state: IAssignmentState): Record<string, IAnswer> {
    return Object.keys(state.answers).length ? state.answers : {};
  },

  getAnswersCheck(_: IAssignmentState, getters: any): IAnswer[] {
    return getters.getQuestions?.map((q: IQuestion) => ({ ...getters.getAnswers[q.uuid], number: q.number })) ?? [];
  },

  getLastQuestionAnswered(state: IAssignmentState, getter: any): IQuestion | null {
    if (!state.answers) return null;

    const answers = Object.values(state.answers).map((a) => a.question_uuid);

    const [question] = getter.getQuestions
      .filter((q: IQuestion) => answers.includes(q.uuid))
      .sort((a: IQuestion, b: IQuestion) => b.number - a.number);

    return question ?? null;
  },

  isAllQuestionsAnswered(state: IAssignmentState): boolean {
    const totalAnswers = Object.keys(state.answers).length;
    const total = state.content?.questions?.length ?? null;
    return !!(total && totalAnswers === total);
  },
};
