import type { GetterTree } from 'vuex';
import type { ITSIA2State as TSIA2State, ITSIA2Window } from '@/store/modules/tsia2/types';
import type { RootState } from '@/store/types';

type TSIA2GetterTree = GetterTree<TSIA2State, RootState>;

export const getters: TSIA2GetterTree = {
  getTSIA2Windows(state: TSIA2State): ITSIA2Window[] {
    const { windows } = state;
    return windows;
  },
  essayTime(state: TSIA2State): number {
    const { essay } = state;
    if (essay) {
      return essay.time_remain || essay.time;
    }

    return 0;
  },
  isOngoingEssay(state: TSIA2State): number {
    const { essay } = state;
    if (essay && essay.is_completed) {
      return essay.is_completed;
    }

    return 0;
  },

  isOngoingSection(state: TSIA2State): boolean {
    const { section } = state;
    if (section && section.answers) {
      return Object.keys(section.answers).length > 0;
    }

    return false;
  },
};
