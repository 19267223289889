import Vue from 'vue';
import '@/plugins/axios';
import App from './App.vue';
import VueCookies from 'vue-cookies';
import router from './router';
import store from './store';
import Argon from './plugins/argon-kit';
import VueProgressBar from 'vue-progressbar';
import Notifications from 'vue-notification';
import AppConfig from '@/plugins/config';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import '@/filters/install';
import Tdm from './plugins/tdm';
import { Settings } from 'luxon';

// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.component('loading', Loading);
// End Loading

Vue.config.productionTip = false;
Vue.use(AppConfig);
Vue.use(VueCookies);
Vue.use(Argon);
Vue.use(Notifications);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Tdm);
Vue.use(VueProgressBar, {
  color: 'rgb(79,214,156)',
  failedColor: 'red',
  thickness: '5px',
  autoFinish: false,
});

Settings.defaultZone = 'America/New_York';
Settings.defaultLocale = 'en';

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
