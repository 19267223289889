import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { IToolsState, IAnnotations, IAnnotation } from './types';

type ToolsGetterTree = GetterTree<IToolsState, RootState>;

export const getters: ToolsGetterTree = {
  getAnnotations(state: IToolsState): IAnnotations {
    return state.annotations || [];
  },
};
