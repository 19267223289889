import type { ErrorType } from '@/store/types';
import type { FriendlyErrorResponse } from './types';
import UserFriendlyError from './UserFriendlyError';

export default class ModuleOneNotCompletedError extends UserFriendlyError {
  constructor(error: FriendlyErrorResponse, code: ErrorType) {
    super(error, code);
    this.name = 'ModuleOneNotCompletedError';
  }
}
