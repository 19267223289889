import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { ITimelineAlert, ITimelineCard, ITimelineState } from './types';

type TimelineGetterTree = GetterTree<ITimelineState, RootState>;

export const getters: TimelineGetterTree = {
  getTimelineCards(state: ITimelineState): ITimelineCard[] {
    return state.cards;
  },

  getTimelineAlerts(state: ITimelineState): ITimelineAlert[] {
    return state.alerts;
  },

  getHasMoreCards(state: ITimelineState): boolean {
    return state.hasMoreCards;
  },
};
