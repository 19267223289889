import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { IVocabularyBuilderState, IWord } from './types';

type VocabularyBuilderGetterTree = GetterTree<IVocabularyBuilderState, RootState>;

export const getters: VocabularyBuilderGetterTree = {
  getWordOfTheDay(state: IVocabularyBuilderState): IWord | null {
      return state.word_of_the_day;
    },
};
