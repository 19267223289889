import type { ActionContext, ActionTree } from 'vuex';
import type { ITSIEssayPayload, ITSILessonTestPayload, ITSIState } from '@/store/modules/tsi/types';
import type { IAnswer, RootState } from '@/store/types';
import type { IStudyHallLesson } from '@/store/modules/studyhall/types';
import type { ILockSection } from '../assessment/types';
import api from '@/ApiCaller';
import router from '@/router';

type TSIActionContext = ActionContext<ITSIState, RootState>;
type TSIActionTree = ActionTree<ITSIState, RootState>;

export const actions: TSIActionTree = {
  async fetchTSI(context: TSIActionContext): Promise<any> {
    const subject = router.currentRoute.params.subject || null;
    await context.dispatch('checkSubject', subject);

    const tsi = await api.get('tsi-homeroom/sections');

    context.commit('setTSI', tsi.data);

    const level = router.currentRoute.params.level || null;
    await context.dispatch('checkLevel', level);
  },

  async checkLevel(context: TSIActionContext, level: number): Promise<any> {
    const levelList = context.rootGetters['authModule/permissions'].tsiLevels;

    const levelFromSomewhere = level || context.getters['getTSILevel'];

    const ret =
      levelFromSomewhere && levelList.includes(parseInt(levelFromSomewhere)) ? levelFromSomewhere : levelList[0];

    await context.dispatch('setLevel', ret);
  },

  async setLevel(context: TSIActionContext, level: number): Promise<any> {
    context.commit('setLevel', level);
  },

  async checkSubject(context: TSIActionContext, subject: string): Promise<any> {
    const subjectList = ['reading', 'writing', 'math'];
    const subjectFromSomewhere = subject || context.getters['getTSISubject'];

    let ret =
      subjectFromSomewhere && subjectList.includes(subjectFromSomewhere) ? subjectFromSomewhere : subjectList[0];

    if (ret === 'essay') {
      ret = 'reading';
    }

    await context.dispatch('setSubject', ret);
  },

  async setSubject(context: TSIActionContext, subject: string): Promise<any> {
    context.commit('setSubject', subject);
  },

  // Assessment
  async fetchAssessments(context: TSIActionContext): Promise<any> {
    const assessments = await api.get('assessments/available');
    context.commit('setAssessments', assessments.data);
  },

  async fetchRelevance(context: TSIActionContext, filter: any): Promise<any> {
    const relevance =
      Object.keys(filter).length > 0 && filter.lesson_id !== 'all'
        ? await api.get(`assessments/relevance/${filter.subject}/${filter.lesson_id}`)
        : await api.get('assessments/relevance');

    context.commit('setRelevance', relevance.data);
  },

  async takeSection(context: TSIActionContext, params: any): Promise<any> {
    const content = await api.get(
      `/tsi-assessment/take-section/${params.subject}/${params.level}/${params.subtype}/${params.set}`
    );
    context.commit('setSection', content.data);
  },

  async fetchPctReviewed(context: TSIActionContext, params: any): Promise<any> {
    const pctReviewed = await api.get(
      `tsi-assessment/pct-reviewed/${params.subject}/${params.level}/${params.subtype}/${params.set}`
    );

    context.commit('setPctReviewed', pctReviewed.data);
  },

  async fetchAnsweredQuestions(context: TSIActionContext, params: any): Promise<any> {
    const content = await api.get(
      `/tsi-assessment/review-questions/${params.subject}/${params.level}/${params.subtype}/${params.set}`
    );
    context.commit('setAnsweredQuestions', content.data);
  },

  async reviewQuestion(_: TSIActionContext, payload: {}): Promise<any> {
    await api.post('/tsi-assessment/question-viewed', payload);
  },

  async saveAnswer(_: TSIActionContext, payload: IAnswer): Promise<any> {
    await api.post('/tsi-assessment/save-answer', payload);
  },

  async saveAnswers(_: TSIActionContext, payload: IAnswer): Promise<any> {
    await api.post('/tsi-assessment/save-answers', payload);
  },

  async finishSection(
    _: TSIActionContext,
    payload: {
      usectionId: number;
      timeRemain: number;
      is_force_finished: 1 | 0;
      force_finished_by: number;
    }
  ): Promise<any> {
    await api.post('/tsi-assessment/finish-section', {
      usection_id: payload.usectionId,
      time_remain: payload.timeRemain,
      is_force_finished: payload.is_force_finished,
      force_finished_by: payload.force_finished_by,
    });
  },

  //StudyHall
  async fetchLessons(context: TSIActionContext): Promise<any> {
    if (!context.getters['getTSISubject']) {
      const subject = router.currentRoute.params.subject || null;
      await context.dispatch('checkSubject', subject);
    }

    if (!context.getters['getTSILevel']) {
      const level = router.currentRoute.params.level || null;
      await context.dispatch('checkLevel', level);
    }

    if (context.getters['getTSILevel'] && context.getters['getTSISubject']) {
      const lessons = await api.get(
        `tsi-studyhall/lessons/${context.getters['getTSISubject']}/${context.getters['getTSILevel']}`
      );

      context.commit('setLessons', lessons.data);
    }
  },

  async fetchStudyHallPctReviewed(context: TSIActionContext): Promise<any> {
    const pctReviewed = await api.get(
      `tsi-studyhall/pct-reviewed/${context.getters['getTSISubject']}/${context.getters['getTSILevel']}`
    );

    context.commit('setStudyHallPctReviewed', pctReviewed.data);
  },

  async setViewedLesson(context: TSIActionContext, payload: ITSILessonTestPayload): Promise<any> {
    const remedial = {
      remedial_id: payload.remedial_id,
      level: payload.level,
    };

    await api.post('tsi-studyhall/viewed/', remedial);
    context.commit('setViewedLesson', payload.remedial_id);
  },

  async loadCurrentLesson(context: TSIActionContext, lesson: IStudyHallLesson): Promise<any> {
    context.commit('setCurrentLesson', lesson);
  },

  async fetchEssay(context: TSIActionContext, params: any): Promise<any> {
    const content = await api.get(`/tsi-assessment/load-essay/${params.level}/${params.set}`);
    context.commit('setEssay', content.data);
  },

  async saveEssayText(context: TSIActionContext, essayText: string): Promise<any> {
    context.commit('setEssayText', essayText);
  },

  async saveEssay(_: TSIActionContext, payload: ITSIEssayPayload): Promise<any> {
    await api.post('tsi-assessment/save-essay', payload);
  },

  async gradeEssay(_: TSIActionContext, payload: ITSIEssayPayload): Promise<any> {
    await api.post('tsi-assessment/grade-essay', payload);
  },

  async lockSection(_: TSIActionContext, payload: ILockSection): Promise<void> {
    await api.post('tsi-assessment/lock-section', {
      usection_id: payload.usection_id,
      is_locked: payload.is_locked,
      locked_by: payload.locked_by,
    });
  },
};
