import ServerError from './ServerError';
import type { FriendlyErrorResponse } from './types';

export default class FatalServerError extends ServerError {
  constructor(error: FriendlyErrorResponse, message: string = '') {
    super(error, message);

    this.name = 'FatalServerError';
    this.message = message;
  }
}
