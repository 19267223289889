import type { ActionContext, ActionTree } from 'vuex';
import type { IActivityReportState } from '@/store/modules/report/activity-report/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type ActivityReportContext = ActionContext<IActivityReportState, RootState>;
type ActivityReportTree = ActionTree<IActivityReportState, RootState>;

export const actions: ActivityReportTree = {
  async fetchActivity(context: ActivityReportContext): Promise<void> {
    const { data } = await ApiCaller.getInstance().get('report/activity');
    context.commit('setActivity', data);
  },
};
