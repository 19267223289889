import Vue from 'vue';
import type { MutationTree } from 'vuex';
import type { IFlowState, IFlowSchedule, IFlowSchedules, FlowStep } from '@/store/modules/flow/types';

type FlowMutationsTree = MutationTree<IFlowState>;

export const mutations: FlowMutationsTree = {
  setSchedules(state: IFlowState, payload: IFlowSchedules) {
    state.schedules = payload;
  },

  setEtl(state: IFlowState, payload: boolean) {
    state.etl = payload;
  },

  setWait(state: IFlowState, payload: number) {
    state.wait = payload;
  },

  setStep(state: IFlowState, payload: FlowStep | null) {
    state.step = payload;
  },

  setPrevious(state: IFlowState, payload: { step: FlowStep; subject: string; subject_label: string; num: number }) {
    state.step = payload.step;
    state.subject = payload.subject;
    state.subject_label = payload.subject_label;
    state.num = payload.num;
  },

  setSection(state: IFlowState, payload: Partial<IFlowState>) {
    const { wait, subject, subject_label, step, num } = payload;

    state.etl = !!payload.etl;
    state.subject = subject || null;
    state.subject_label = subject_label || null;
    state.wait = wait || 0;
    state.step = step || null;
    state.num = num || null;
  },

  setTakeSection(state: IFlowState, payload: Partial<IFlowState>) {
    const { etl } = state;

    state.etl = etl === undefined || etl === null ? null : etl;
    state.wait = payload.wait || 0;
    state.step = payload.step || null;
  },

  finishSection(
    state: IFlowState,
    payload: {
      schedule: IFlowSchedule;
      step: FlowStep;
      subject: string;
      num: number;
    }
  ) {
    if (state.schedules) {
      Vue.set(state.schedules, payload.num, payload.schedule);
      state.step = payload.step;
    }
  },

  finishFlow(state: IFlowState) {
    state.schedules = null;
    state.subject = null;
    state.subject_label = null;
    state.num = null;
    state.wait = null;
    state.step = null;
    state.etl = null;
  },
};
