import ServerError from './ServerError';
import type { ErrorType } from '@/store/types';
import type { FriendlyErrorResponse } from './types';

export default class UserFriendlyError extends ServerError {
  constructor(error: FriendlyErrorResponse, code: ErrorType, message: string = '') {
    super(error, message);

    this.name = 'UserFriendlyError';
    this.message = error.response?.data?.message ?? '';

    this.code = code;
  }

  public readonly code: ErrorType;
}
