import type { Module } from 'vuex';
import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import type { IScheduleState } from './types';
import { getters } from './getters';
import type { RootState } from '@/store/types';

const namespaced: boolean = true;

export const scheduleModule: Module<IScheduleState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
