import type { MutationTree } from 'vuex';
import type { IExamRoomState } from '@/store/modules/examroom/types';

type ExamRoomMutationsTree = MutationTree<IExamRoomState>;

export const mutations: ExamRoomMutationsTree = {
  setAssessments(state: IExamRoomState, payload: IExamRoomState) {
    state.windows = payload.windows ? payload.windows : null;
    state.averages = payload.averages ? payload.averages : null;
    state.assessments = payload.assessments ? payload.assessments : [];
    state.assessments_diagnostic = payload.assessments_diagnostic ? payload.assessments_diagnostic : [];
    state.superscore = payload.superscore ? payload.superscore : null;
  },
};
