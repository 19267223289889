import type { ActionContext, ActionTree } from 'vuex';
import type { ITestSectionScoresReportState } from '@/store/modules/report/test-section-scores-report/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type TestSectionScoresReportContext = ActionContext<ITestSectionScoresReportState, RootState>;
type TestSectionScoresReportTree = ActionTree<ITestSectionScoresReportState, RootState>;

export const actions: TestSectionScoresReportTree = {
  async fetchTestSectionScores(context: TestSectionScoresReportContext): Promise<void> {
    const { data } = await ApiCaller.getInstance().get('report/test-section-scores');
    context.commit('setTestSectionScores', data);
  },
};
