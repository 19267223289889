import type { IContentQuestion } from '@/store/modules/content/types';

const message =
  'Because the answers to one or more questions were not saved, this assessment cannot be completed. Please contact your teacher or a school administrator for assistance. (Error code: E1601)';

export default class UnsavedAnswersError extends Error {
  constructor(questions: IContentQuestion[]) {
    super(message);

    this.name = 'UnsavedAnswersError';
    this.message = message;
    this.questions = questions;
  }

  public readonly questions: IContentQuestion[];
}
