import type { MutationTree } from 'vuex';
import type { IAuthState, IUser } from '@/store/modules/auth/types';
import { ApiCaller } from '@/ApiCaller';
import { resetStore } from '@/store';
import { ApiQtaCaller } from '@/ApiQtaCaller';
import { ApiReactStudentCaller } from '@/ApiReactStudentCaller';

type AuthMutationTree = MutationTree<IAuthState>;

export const mutations: AuthMutationTree = {
  setToken(state: IAuthState, accessToken: string) {
    state.accessToken = accessToken;
    ApiCaller.getInstance().setAuthorizationHeader(accessToken);
    ApiQtaCaller.getInstance().setAuthorizationHeader(accessToken);
    ApiReactStudentCaller.getInstance().setAuthorizationHeader(accessToken);
  },

  setLogged(state: IAuthState, isLogged: boolean) {
    state.isLogged = isLogged;
  },

  setUser(state: IAuthState, account: IUser) {
    state.account = account;
  },

  updatePasswordNeeded(state: IAuthState, needed: 0 | 1) {
    const { account } = state;
    if (account?.need_password_reset) account.need_password_reset = needed || 0;
  },

  unsetToken(state: IAuthState) {
    state.accessToken = null;
    ApiCaller.getInstance().clearAuthorizationHeader();
    ApiQtaCaller.getInstance().clearAuthorizationHeader();
    ApiReactStudentCaller.getInstance().clearAuthorizationHeader();
  },

  setLogout(state: IAuthState) {
    state.accessToken = null;
    state.isLogged = false;
    state.account = null;
    ApiCaller.getInstance().clearAuthorizationHeader();
    resetStore();
  },

  setHomeRoomOn(state: IAuthState, payload: 0 | 1) {
    const { account } = state;

    if (account && account.permissions) {
      account.permissions.homeRoomOn = payload;
    }
  },

  complete(state: IAuthState) {
    state.isCompleted = true;
  },

  setReferrerURL(state: IAuthState, referrer: string): void {
    state.referrerURL = referrer;
  },
};
