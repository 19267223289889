import type { ActionContext, ActionTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { IAnnotation, IToolsState } from './types';

type ToolsActionContext = ActionContext<IToolsState, RootState>;
type ToolsActionTree = ActionTree<IToolsState, RootState>;

export const actions: ToolsActionTree = {
  async saveAnnotation(context: ToolsActionContext, payload: IAnnotation): Promise<void> {
    context.commit('setAnnotation', payload);
  },

  async deleteAnnotation(context: ToolsActionContext, payload: string): Promise<void> {
    context.commit('deleteAnnotation', payload);
  },
};
