import type { Module } from 'vuex';
import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import type { ITSIState } from '@/store/modules/tsi/types';
import { getters } from '@/store/modules/tsi/getters';
import type { RootState } from '@/store/types';

const namespaced: boolean = true;

export const tsiModule: Module<ITSIState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
