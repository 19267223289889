import type { GetterTree } from 'vuex';
import type {
  IContentAssets,
  IContentPassage,
  IContentQuestion,
  IContentQuizSection,
  IContentSection,
  IContentState as ContentState,
} from '@/store/modules/content/types';
import type { RootState } from '@/store/types';

type ContentGetterTree = GetterTree<ContentState, RootState>;

export const getters: ContentGetterTree = {
  getSection(state: ContentState): IContentSection | IContentQuizSection | null {
    if (state.section) {
      return state.section;
    }
    return null;
  },

  getQuestions(state: ContentState): IContentQuestion[] | null {
    if (state.questions) {
      return state.questions;
    }
    return null;
  },

  getPassages(state: ContentState): IContentPassage[] | null {
    if (state.passages) {
      return state.passages;
    }
    return null;
  },

  getAssets(state: ContentState): IContentAssets | null {
    if (state.assets) {
      return state.assets;
    }
    return null;
  },
};
