import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { IStudyHallState } from './types';

type StudyHallGetterTree = GetterTree<IStudyHallState, RootState>;

export const getters: StudyHallGetterTree = {
  period(state: IStudyHallState): number | null {
    return state.period;
  },

  testsAvailable(state: IStudyHallState): number[] | string[] | undefined {
    return state.testsAvailable;
  },
};
