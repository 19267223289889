import type { ActionContext, ActionTree } from 'vuex';
import type { IErrorState, IError } from '@/store/modules/error/types';
import type { RootState } from '@/store/types';

type ErrorActionContext = ActionContext<IErrorState, RootState>;
type ErrorActionTree = ActionTree<IErrorState, RootState>;

export const actions: ErrorActionTree = {
  async error(context: ErrorActionContext, data: IError): Promise<any> {
    context.commit('setError', data);
  },

  async clearErrors(context: ErrorActionContext): Promise<any> {
    context.commit('unsetError');
  },
};
