import type { MutationTree } from 'vuex';
import type { IReportsLinks, IReportsLinksState } from '@/store/modules/report/reports-links/types';

type ReportsLinksTree = MutationTree<IReportsLinksState>;

export const mutations: ReportsLinksTree = {
  setReportsLinks(state: IReportsLinksState, links: IReportsLinks[]) {
    state.links = links;
  },
};
