import type { MutationTree } from 'vuex';
import type {
  IAssessmentState,
  IAssessmentReviewQuestion,
  IAssessmentFeedback,
  IQuestionLessons,
  IAssessmentPctReview,
  IPerformance,
  IAssignment,
  IQuestionExplanation,
  IAssessmentFeedbackStandard,
} from '@/store/modules/assessment/types';
import type { IUSection } from '@/store/types';
import type { IContentState } from '@/store/modules/content/types';

type AssessmentMutationsTree = MutationTree<IAssessmentState>;

export const mutations: AssessmentMutationsTree = {
  setAssessments(state: IAssessmentState, sections: IUSection[]) {
    state.sections = sections;
  },

  setPerformance(state: IAssessmentState, performance: IPerformance) {
    state.performance = performance;
  },

  setAssignments(state: IAssessmentState, assignments: IAssignment[]) {
    state.assignments = assignments;
  },

  setSection(state: IAssessmentState, section: IUSection) {
    state.section = section;
  },

  updateSectionCt(state: IAssessmentState, ct: number) {
    if (state.section) {
      state.section.ct += ct;
    }
  },

  setSectionTime(state: IAssessmentState, time: number) {
    if (state.section) {
      state.section.time = time;
    }
  },
  unsetSection(state: IAssessmentState) {
    state.section = null;
  },
  setAnsweredQuestions(state: IAssessmentState, questions: IAssessmentReviewQuestion[]) {
    state.answeredQuestions = questions;
  },
  setAssessmentFeedback(state: IAssessmentState, feedback: IAssessmentFeedback) {
    state.feedback = feedback;
  },
  setAssessmentFeedbackStandards(state: IAssessmentState, feedbackStandards: IAssessmentFeedbackStandard[]) {
    state.feedbackStandards = feedbackStandards;
  },
  setQuestionLessons(state: IAssessmentState, lessons: IQuestionLessons[]) {
    state.questionLessons = lessons;
  },
  setPctReviewed(state: IAssessmentState, pctReviewed: IAssessmentPctReview) {
    state.pctReview = pctReviewed;
  },
  setQuestionExplanation(state: IAssessmentState, payload: { questionId: number; explanation: IQuestionExplanation }) {
    const question = state.answeredQuestions?.find((q) => q.id === payload.questionId);
    if (!question || !payload.explanation) return false;

    const explanation = payload.explanation;

    if (explanation.steps) {
      explanation.steps.forEach((step) => {
        step.student_answer = '';
      });
    }

    question.explanation = explanation;
  },
};
