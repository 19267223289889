import type { ActionContext, ActionTree } from 'vuex';
import type { ISectionState } from '@/store/modules/section/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type SectionActionContext = ActionContext<ISectionState, RootState>;
type SectionActionTree = ActionTree<ISectionState, RootState>;

export const actions: SectionActionTree = {
  async fetchSections(context: SectionActionContext): Promise<any> {
    const sections = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}homeroom/sections/${context.rootState.product}`
    );
    context.commit('setSections', sections.data);
  },
};
