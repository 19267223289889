import type { IStudyHallState } from '@/store/modules/studyhall/types';

export const state: IStudyHallState = {
  lessons: [],
  missedSkipped: [],
  pctReview: null,
  currentLesson: null,
  currentQuestion: null,
  period: null,
};
