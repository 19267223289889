import tdm, { useAuthorization } from '@eprep/tdm-vue';
import { Config } from './config';

useAuthorization(() => {
  const { authModule } = JSON.parse(localStorage.getItem(Config.LocalStorage) ?? '{authModule:{accessToken: "" }}');
  const { accessToken: token } = authModule;

  return { token };
});

export default {
  install(Vue: any) {
    Vue.use(tdm, {
      API: import.meta.env.VITE_APP_TDM_API,
      WSS: import.meta.env.VITE_APP_TDM_WSS,
    });
  },
};
