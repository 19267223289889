import type { IExamRoomState } from '@/store/modules/examroom/types';

export const state: IExamRoomState = {
  windows: null,
  averages: null,
  // DSAT/PSAT
  assessments: [],
  assessments_diagnostic: [],
  // Both
  superscore: null,
};
