import type { ActionContext, ActionTree } from 'vuex';
import type { IScoreProgressionState } from '@/store/modules/report/score-progression/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type ScoreProgressionContext = ActionContext<IScoreProgressionState, RootState>;
type ScoreProgressionTree = ActionTree<IScoreProgressionState, RootState>;

export const actions: ScoreProgressionTree = {
  async fetchChart(context: ScoreProgressionContext): Promise<any> {
    const chart = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}homeroom/score-progression-chart`
    );
    context.commit('setChart', chart.data);
  },

  async fetchTable(context: ScoreProgressionContext): Promise<any> {
    const table = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}homeroom/score-progression-table`
    );
    context.commit('setTable', table.data);
  },
};
