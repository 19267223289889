import type { IPracticeAssessmentState } from '@/store/modules/practice/types';

export const state: IPracticeAssessmentState = {
  sections: [],
  section: null,
  feedback: null,
  questionLessons: null,
  answeredQuestions: null,
  completedSections: { english: {}, math: {}, reading: {}, science: {} },
  pctReview: null,
};
