import type { Module } from 'vuex';
import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import type { ITSIA2State } from '@/store/modules/tsia2/types';
import { getters } from '@/store/modules/tsia2/getters';
import type { RootState } from '@/store/types';

const namespaced: boolean = true;

export const tsia2Module: Module<ITSIA2State, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
