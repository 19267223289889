import store from '@/store';
import { BasicsPeriod } from '@/store/types';
import { SubjectsTSIA2 } from '@/store/modules/tsia2/types';
import { isReact } from '@/helpers';
import type { IContentQuestion } from '@/store/modules/content/types';

// Vocabulary Builder and Wordsmith
export const isVbOrWordsmith = (): 'vb' | 'wordsmith' => {
  const themeName = store.getters.theme.name;
  if (themeName === 'cert' || themeName === 'cpa') {
    return 'vb';
  }
  return 'wordsmith';
};

export const vbOrWordsmithLabel = (): string => {
  if (isVbOrWordsmith() === 'vb') {
    return 'Vocabulary Builder';
  }
  return 'WordSmith';
};

export const vbOrWordsmithSlug = (): string => {
  if (isVbOrWordsmith() === 'vb') {
    return 'vocabulary-builder';
  }
  return 'wordsmith';
};
// END Vocabulary Builder and Wordsmith

export const getPeriodName = (period: BasicsPeriod | number): 'fall' | 'spring' | 'winter' =>
  period === BasicsPeriod.fall ? 'fall' : period === BasicsPeriod.winter ? 'winter' : 'spring';

export const genSubjectColor = (subject: string) => {
  switch (subject.toLowerCase().trim()) {
    case 'english':
      return '#3366cc';
    case 'math':
      return '#C02F7F';
    case 'reading':
    case 'rw':
      return '#d09133';
    case 'science':
      return '#359499';
    case 'composite':
      return '#4b495a';
    default:
      return '#000000';
  }
};

export const colorClass = (val: number) => {
  if (val > 0) {
    return 'text-success';
  } else if (val < 0) {
    return 'text-danger';
  }
};

export const getSubjectTSIA2 = (subject: SubjectsTSIA2) => {
  switch (subject) {
    case SubjectsTSIA2.Math:
      return 'Math';

    case SubjectsTSIA2.ELAR:
      return 'ELAR';
  }
};

// TODO: obviously not a great, but one that works
export const dispatcherName = (dispatcher: string) => {
  if (!isReact(store.getters['activeProduct'])) {
    return dispatcher;
  }

  const parts = dispatcher.split('/');
  return parts[0] + '/react_' + parts[1];
};

export const prepareContentQuestions = (questions: IContentQuestion[]): IContentQuestion[] => {
  const questionsRet = JSON.parse(JSON.stringify(questions));
  questionsRet.forEach((question: IContentQuestion) => {
    question.student_answer = 'SKIP';
    question.answered = false;
    question.flagged = false;
    question.tools = {
      unmasked: [],
      eliminated: [],
      cross_out: [],
    };
  });
  return questionsRet;
};
