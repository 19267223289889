import type { MutationTree } from 'vuex';
import type {
  IQuizState,
  IQuizCorrectIncorrect,
  IQuizUSection,
  IQuizRelevance,
  IQuizLesson,
  IQuizReviewQuestion,
  IQuizPctReview,
} from '@/store/modules/quiz/types';
import type { IContentQuizSection } from '@/store/modules/content/types';

type QuizMutationsTree = MutationTree<IQuizState>;

export const mutations: QuizMutationsTree = {
  setQuizzes(state: IQuizState, sections: IQuizUSection) {
    state.sections = sections;
  },
  setLessons(state: IQuizState, lessons: IQuizLesson) {
    state.lessons = lessons;
  },
  setRelevance(state: IQuizState, relevance: IQuizRelevance) {
    state.relevance = relevance;
  },
  setCorrectIncorrect(state: IQuizState, correctIncorrect: IQuizCorrectIncorrect) {
    state.correctIncorrect = correctIncorrect;
  },
  setSection(state: IQuizState, section: IContentQuizSection) {
    state.section = section;
  },
  setAnsweredQuestions(state: IQuizState, questions: IQuizReviewQuestion[]) {
    state.answeredQuestions = questions;
  },
  setPctReviewed(state: IQuizState, pctReviewed: IQuizPctReview) {
    state.pctReview = pctReviewed;
  },
};
