import type { MutationTree } from 'vuex';
import type {
  IPracticeAssessmentState,
  IPracticeAssessmentReviewQuestion,
  IPracticeAssessmentFeedback,
  IQuestionLessons,
  IPracticeAssessmentPctReview,
} from '@/store/modules/practice/types';
import type { IUSection } from '@/store/types';

type PracticeAssessmentMutationsTree = MutationTree<IPracticeAssessmentState>;

export const mutations: PracticeAssessmentMutationsTree = {
  setPracticeAssessments(state: IPracticeAssessmentState, sections: IUSection) {
    state.sections = sections;
  },
  setSection(state: IPracticeAssessmentState, section: IUSection) {
    state.section = section;
  },
  setAnsweredQuestions(state: IPracticeAssessmentState, questions: IPracticeAssessmentReviewQuestion[]) {
    state.answeredQuestions = questions;
  },
  setPracticeAssessmentFeedback(state: IPracticeAssessmentState, feedback: IPracticeAssessmentFeedback) {
    state.feedback = feedback;
  },
  setQuestionLessons(state: IPracticeAssessmentState, lessons: IQuestionLessons[]) {
    state.questionLessons = lessons;
  },

  setPctReviewed(state: IPracticeAssessmentState, pctReviewed: IPracticeAssessmentPctReview) {
    state.pctReview = pctReviewed;
  },

  setCompletedSections(state: IPracticeAssessmentState, sections: {}) {
    state.completedSections = { ...state.completedSections, ...sections };
  },
};
