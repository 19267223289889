import type { GetterTree } from 'vuex';
import type {
  ITestSectionScoresData,
  ITestSectionScoresReportState,
} from '@/store/modules/report/test-section-scores-report/types';
import type { RootState } from '@/store/types';

type TestSectionScoresReportGetter = GetterTree<ITestSectionScoresReportState, RootState>;

export const getters: TestSectionScoresReportGetter = {
  getTestSectionScores(state: ITestSectionScoresReportState): ITestSectionScoresData | null {
    const { testSectionScores } = state;
    return testSectionScores;
  },
};
