import Vue from 'vue';
import * as filters from './index';

// MIGRATE: Need refactory to support Vue3
type Filter = {
  name: string;
  fn: (args: unknown[]) => unknown;
};

for (const key in filters) {
  if (Object.prototype.hasOwnProperty.call(filters, key)) {
    // @ts-ignore
    const filter = filters[key] as unknown as Filter;
    Vue.filter(filter.name, filter.fn);
  }
}
