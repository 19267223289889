import type { GetterTree } from 'vuex';
import type {
  IPracticeAssessmentState as PracticeAssessmentState,
  IPracticeSection,
} from '@/store/modules/practice/types';
import type { RootState } from '@/store/types';
import type { IUSection } from '@/store/types';

type PracticeAssessmentGetterTree = GetterTree<PracticeAssessmentState, RootState>;

export const getters: PracticeAssessmentGetterTree = {
  sections(state: PracticeAssessmentState): IUSection | [] {
    const { sections } = state;
    if (sections) {
      return sections;
    }

    return [];
  },

  sectionTime(state: PracticeAssessmentState): number {
    const { section } = state;
    return section?.time ?? 0;
  },

  nextSection(state: PracticeAssessmentState): string {
    const nextSection = Object.entries(state.completedSections)
      .filter((s) => !Object.values(s[1] as IPracticeSection).length)
      .map((s) => s[0])
      .shift();

    return nextSection || '';
  },

  isSectionsFinished(state: PracticeAssessmentState): boolean {
    return Object.entries(state.completedSections).every(
      (obj) => obj[1] && Object.values(obj[1] as IPracticeSection).length
    );
  },

  completedSections(state: PracticeAssessmentState): {} {
    return state.completedSections;
  },

  isOngoingSection(state: PracticeAssessmentState): boolean {
    return Object.entries(state.completedSections).some(
      (obj) => obj[1] && Object.values(obj[1] as IPracticeSection).length
    );
  },
};
