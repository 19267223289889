import UserFriendlyError from './UserFriendlyError';
import type { ErrorType } from '@/store/types';
import type { FriendlyErrorResponse } from './types';

export default class SaveAnswerError extends UserFriendlyError {
  constructor(error: FriendlyErrorResponse, code: ErrorType) {
    super(error, code);

    this.name = 'SaveAnswerError';
  }
}
