import UserFriendlyError from './UserFriendlyError';
import { ErrorType } from '@/store/types';
import ForcedFinishSectionError from './ForcedFinishSectionError';
import LockedSectionError from './LockedSectionError';
import SaveAnswerError from '@/errors/SaveAnswerError';
import FinishSectionError from './FinishSectionError';
import type { FriendlyErrorResponse } from './types';
import ModuleOneNotCompletedError from '@/errors/ModuleOneNotCompletedError';

const regex = new RegExp(/(\([Ee]rror\s+[Cc]ode:\s+)(E[0-9]+)(\))/);

export default (error: FriendlyErrorResponse): UserFriendlyError => {
  const match = regex.exec(error.response?.data?.message ?? '');

  const code = <ErrorType>match?.[2];

  switch (code) {
    case ErrorType.E1612:
      return new SaveAnswerError(error, code);
    case ErrorType.E1601:
    case ErrorType.E1606:
    case ErrorType.E1613:
      return new FinishSectionError(error, code);
    case ErrorType.E1607:
      return new ForcedFinishSectionError(error, code);
    case ErrorType.E1608:
      return new LockedSectionError(error, code);
    case ErrorType.E1203:
      return new ModuleOneNotCompletedError(error, code);
    default:
      return new UserFriendlyError(error, code as ErrorType);
  }
};
