import type { ActionContext, ActionTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { ThemeConfig } from '@/plugins/config';

type RootActionContext = ActionContext<RootState, RootState>;
type RootActionTree = ActionTree<RootState, RootState>;

export const actions: RootActionTree = {
  async setOnline(context: RootActionContext, payload: boolean): Promise<void> {
    context.commit('setOnline', payload);
  },

  async setCurrentTheme(context: RootActionContext, payload: ThemeConfig): Promise<any> {
    context.commit('setCurrentTheme', payload);
  },

  async setProduct(context: RootActionContext, payload: string): Promise<void> {
    context.commit('setProduct', payload);
  },

  async setLoading(context: RootActionContext, payload: boolean): Promise<void> {
    context.commit('setLoading', payload);
  },
};
