import type { Module } from 'vuex';
import type { RootState } from '@/store/types';
import type { IGraduationPlannerState } from '@/store/modules/graduationplanner/types';
import { actions } from '@/store/modules/graduationplanner/actions';
import { getters } from '@/store/modules/graduationplanner/getters';
import { mutations } from '@/store/modules/graduationplanner/mutations';
import { state } from '@/store/modules/graduationplanner/state';

const namespaced: boolean = true;

type GraduationPlannerModule = Module<IGraduationPlannerState, RootState>;

export const graduationPlannerModule: GraduationPlannerModule = {
  actions,
  getters,
  mutations,
  namespaced,
  state,
};
