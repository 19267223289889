//Base
import Badge from '@/components/base/template/vue-argon-design-template/Badge.vue';
import BaseAlert from '@/components/base/template/BaseAlert.vue';
import BaseButton from '@/components/base/template/BaseButton.vue';
import BaseModal from '@/components/base/template/BaseModal.vue';
import BaseCheckbox from '@/components/base/template/vue-argon-design-template/BaseCheckbox.vue';
import BaseInput from '@/components/base/template/vue-argon-design-template/BaseInput.vue';
import BasePagination from '@/components/base/template/vue-argon-design-template/BasePagination.vue';
import BaseProgress from '@/components/base/template/vue-argon-design-template/BaseProgress.vue';
import BaseRadio from '@/components/base/template/vue-argon-design-template/BaseRadio.vue';
import BaseSlider from '@/components/base/template/vue-argon-design-template/BaseSlider.vue';
import BaseSwitch from '@/components/base/template/vue-argon-design-template/BaseSwitch.vue';
import Card from '@/components/base/template/vue-argon-design-template/Card.vue';
import Icon from '@/components/base/template/vue-argon-design-template/Icon.vue';

// tabs
import Tab from '@/components/base/template/vue-argon-design-template/Tabs/Tab.vue';
import TabPane from '@/components/base/template/vue-argon-design-template/Tabs/TabPane.vue';
import Tabs from '@/components/base/template/vue-argon-design-template/Tabs/Tabs.vue';
import TabsLayout from '@/components/base/template/vue-argon-design-template/Tabs/TabsLayout.vue';
// import PillsLayout from '@/components/base/template/vue-argon-design-template/Tabs/PillsLayout.vue';

//Cert
import ButtonComponent from '@/components/base/ButtonComponent.vue';
import ReturnComponent from '@/components/base/ReturnComponent.vue';
import PopupComponent from '@/components/base/PopupComponent.vue';

export default {
  install(Vue: any) {
    //Base
    // @ts-ignore
    Vue.component(Badge.name, Badge);
    // @ts-ignore
    Vue.component(BaseInput.name, BaseInput);
    // @ts-ignore
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    // @ts-ignore
    Vue.component(BasePagination.name, BasePagination);
    // @ts-ignore
    Vue.component(BaseProgress.name, BaseProgress);
    // @ts-ignore
    Vue.component(BaseRadio.name, BaseRadio);
    // @ts-ignore
    Vue.component(BaseSlider.name, BaseSlider);
    // @ts-ignore
    Vue.component(BaseSwitch.name, BaseSwitch);
    // @ts-ignore
    Vue.component(Card.name, Card);
    // @ts-ignore
    Vue.component(Icon.name, Icon);

    // @ts-ignore
    Vue.component(BaseAlert.options.name, BaseAlert);
    // @ts-ignore
    Vue.component(BaseButton.options.name, BaseButton);
    // @ts-ignore
    Vue.component(BaseModal.options.name, BaseModal);

    // Tabs
    // @ts-ignore
    Vue.component(Tab.name, Tab);
    // @ts-ignore
    Vue.component(TabPane.name, TabPane);
    // @ts-ignore
    Vue.component(Tabs.name, Tabs);
    // @ts-ignore
    Vue.component(TabsLayout.name, TabsLayout);

    //Cert
    // @ts-ignore
    Vue.component(ButtonComponent.options.name, ButtonComponent);
    // @ts-ignore
    Vue.component(ReturnComponent.options.name, ReturnComponent);
    // @ts-ignore
    Vue.component(PopupComponent.options.name, PopupComponent);
  },
};
