import type { MutationTree } from 'vuex';
import type {
  IStudyHallState,
  IStudyHallLesson,
  IStudyHallMissedSkipped,
  IStudyHallPctReview,
} from '@/store/modules/studyhall/types';

type StudyHallMutationsTree = MutationTree<IStudyHallState>;

export const mutations: StudyHallMutationsTree = {
  clearState(state: IStudyHallState, payload: IStudyHallState): void {
    state.lessons = payload.lessons;
    state.missedSkipped = payload.missedSkipped;
    state.pctReview = payload.pctReview;
    state.currentLesson = payload.currentLesson;
    state.currentQuestion = payload.currentQuestion;
    state.period = payload.period;
  },

  setLessons(state: IStudyHallState, lessons: IStudyHallLesson[]): void {
    state.lessons = lessons;
  },

  setCurrentLesson(state: IStudyHallState, currentLesson: IStudyHallLesson): void {
    state.currentLesson = currentLesson;
  },

  setMissedSkipped(state: IStudyHallState, missedSkipped: IStudyHallMissedSkipped[]): void {
    state.missedSkipped = missedSkipped;
  },

  setCurrentQuestion(state: IStudyHallState, question: IStudyHallMissedSkipped): void {
    state.currentQuestion = question;
  },

  setPctReviewed(state: IStudyHallState, pctReviewed: IStudyHallPctReview): void {
    state.pctReview = pctReviewed;
  },

  setViewedLesson(state: IStudyHallState, lessonId: number): void {
    const lesson = state.lessons.find((l) => l.id === lessonId);
    if (lesson) lesson.is_reviewed = 1;
  },

  setViewedQuestion(state: IStudyHallState, questionId: number): void {
    const question = state.missedSkipped.find((q) => q.id === questionId);
    if (question) question.is_reviewed = 1;
  },

  setTestsAvailable(state: IStudyHallState, testsAvailable: number[] | string[]): void {
    state.testsAvailable = testsAvailable;
  },

  changeCurrentPeriod(state: IStudyHallState, period: number): void {
    state.period = period;
  },
};
