import type { IVocabularyBuilderState } from '@/store/modules/vocabulary-builder/types';

export const state: IVocabularyBuilderState = {
  words: [],
  review: [],
  test: null,
  test_review: null,
  word_of_the_day: null,
  filter: {
    levels: ['1', '2', '3'],
    sorts: ['yes', 'maybe', 'no', 'unsorted'],
    display: ['definition', 'sentence', 'comments'],
    question_types: ['multiple-choice', 'yes-no', 'sentence-completion'],
    term_count: 10,
    query: '',
    count: [],
  },
};
