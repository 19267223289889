import type { GetterTree } from 'vuex';
import type { RootState } from '@/store/types';
import type { IGraduationPlannerState } from '@/store/modules/graduationplanner/types';

type GraduationPlannerGetterTree = GetterTree<IGraduationPlannerState, RootState>;

export const getters: GraduationPlannerGetterTree = {
  ssoToken(state: IGraduationPlannerState): string | undefined {
    return state.ssoToken;
  },
};
