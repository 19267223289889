import type { MutationTree } from 'vuex';
import type { IGraduationPlannerState } from '@/store/modules/graduationplanner/types';

type GraduationPlannerMutationTree = MutationTree<IGraduationPlannerState>;

export const mutations: GraduationPlannerMutationTree = {
  setSsoToken: (state: IGraduationPlannerState, payload: string) => {
    state.ssoToken = payload;
  },
};
