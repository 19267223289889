<template>
  <footer class="footer footer-global" id="footer" ref="footerContainer">
    <div class="container">
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-6">
          <div class="copyright">
            &copy; {{ year }}
            <template v-if="theme.name === 'cert' || theme.name === 'cpa'">
              {{ theme.name === 'cert' ? theme.title : theme.title + ' powered by ePrep' }}
            </template>
            <template v-else> - All Rights Reserved </template>
          </div>
        </div>
        <div class="col-md-6">
          <ul class="nav nav-footer justify-content-end">
            <template v-if="theme.name === 'cert'">
              <li class="nav-item">
                <a class="nav-link p-2" href="https://certforschools.com/system-requirements">
                  <span class="nav-link-inner--text">System Requirements</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link p-2" href="https://certforschools.com/privacy-policy">
                  <span class="nav-link-inner--text">Privacy Policy</span>
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import type { ThemeConfig } from '@/plugins/config';

@Component({
  name: 'app-footer',
})
export default class AppFooter extends Vue {
  @Getter('theme')
  public theme!: ThemeConfig;

  get year() {
    return new Date().getFullYear();
  }
}
</script>
