import type { Module } from 'vuex';
import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import type { IPracticeAssessmentState } from '@/store/modules/practice/types';
import { getters } from '@/store/modules/practice/getters';
import type { RootState } from '@/store/types';

const namespaced: boolean = true;

export const practiceModule: Module<IPracticeAssessmentState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
