import type { IContentQuestion } from '@/store/modules/content/types';

export default class NotSavedAnswerError extends Error {
  constructor(questions: IContentQuestion[], message: string = '') {
    super(message || 'Error: The answer was not saved!');

    this.name = 'NotSavedAnswerError';
    this.questions = questions;
  }

  public readonly questions: IContentQuestion[];
}
