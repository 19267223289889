import type { MutationTree } from 'vuex';
import type { ActivityData, IActivityReportState } from '@/store/modules/report/activity-report/types';

type ActivityReportTree = MutationTree<IActivityReportState>;

export const mutations: ActivityReportTree = {
  setActivity(state: IActivityReportState, payload: ActivityData): void {
    state.activity = payload;
  },
};
