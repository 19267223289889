import type { Module } from 'vuex';
import { state } from './state';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import type { IActivityReportState } from '@/store/modules/report/activity-report/types';
import type { RootState } from '@/store/types';

const namespaced: boolean = true;

export const activityReportModule: Module<IActivityReportState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
