import type { GetterTree } from 'vuex';
import type { IErrorState } from '@/store/modules/error/types';
import type { RootState } from '@/store/types';

type ErrorGetter = GetterTree<IErrorState, RootState>;

export const getters: ErrorGetter = {
  getErrorMsgs(state: IErrorState): string[] {
    const { errorArray } = state;
    return errorArray;
  },
  getErrorStatus(state: IErrorState): boolean {
    const { error } = state;
    return error;
  },
};
