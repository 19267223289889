import type { ITSIA2State } from '@/store/modules/tsia2/types';

export const state: ITSIA2State = {
  windows: [],
  section: null,
  next_question_id: null,
  answeredQuestions: null,
  pctReview: null,
  essay: null,
  questionLessons: null,
};
