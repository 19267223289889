import type { GetterTree } from 'vuex';
import type { BasicsPeriod, RootState } from '@/store/types';
import { BasicProducts } from '@/store/types';
import type { IAuthState, IPermissions, IUser } from '@/store/modules/auth/types';
import { DateTime } from 'luxon';
import { vbOrWordsmithSlug } from '@/utils';

type AuthGetterTree = GetterTree<IAuthState, RootState>;

const getPermissions = (state: IAuthState): string[] => {
  const { account } = state;
  if (account?.permissions) {
    const ret = Object.entries(account.permissions)
      .filter((p) => p[1])
      .map((p) => {
        switch (p[0]) {
          case 'homeRoom':
            return { ...p, [0]: p[0].toLowerCase(), order: 1 };
          case 'tsi':
            return { ...p, order: 2 };
          case 'examRoom':
            return { ...p, order: 3 };
          case 'studyHall':
            return { ...p, order: 4 };
          case 'quizzes':
            return { ...p, order: 5 };
          case 'dashboard':
            return { ...p, order: 6 };
          case 'vb':
            return { ...p, order: 7 };
          case 'gp':
            return { ...p, order: 8 };
          case 'qta':
            return { ...p, order: 9 };
          case 'react':
            return { ...p, order: 10 };
          default:
            return { ...p, order: null };
        }
      })
      .filter((a) => a.order)
      .sort((a, b) => {
        if (a.order && b.order) {
          return a.order > b.order ? 1 : -1;
        }

        return 0;
      })
      .map((p) => p[0].toLowerCase());

    return ret;
  }

  return [];
};

export const getters: AuthGetterTree = {
  account(state: IAuthState): IUser | null {
    return state && state.account;
  },

  grade(state: IAuthState): number {
    return state?.account?.grade ?? 0;
  },

  permissions(state: IAuthState): IPermissions | null {
    return (state.account && state.account.permissions) || null;
  },

  homeRoute(state: IAuthState, _getters, _rootState, rootGetters): string {
    if (rootGetters.activeProduct === 'qta') {
      return '/assignments';
    } else if (rootGetters.activeProduct === 'tsi') {
      return '/tsi/progress';
    } else if (rootGetters.activeProduct === 'gp') {
      return '/graduation-planner/start';
    } else {
      return (
        getPermissions(state)
          .map((p) => {
            switch (p) {
              case 'homeroom':
                return '/home-room';
              case 'tsi':
                return '/tsi';
              case 'vb':
                return `/${vbOrWordsmithSlug()}`;
              case 'examroom':
                return '/exam-room';
              case 'studyhall':
                return '/study-hall';
              case 'quizzes':
                return '/quizzes';
              case 'dashboard':
                return '/dashboard';
              case 'qta':
                return '/assignments';
              default:
                return '/logout';
            }
          })
          .shift() || ''
      );
    }
  },

  isLogged(state: IAuthState): boolean {
    return !!state.account;
  },

  isExpired(state: IAuthState): boolean {
    const daysRemaining = state.account?.activeProduct?.days_remaining ?? 0;
    return daysRemaining < 0;
  },

  isOfflineTest(state: IAuthState): boolean {
    const isOnLine = !!(state.account && state.account.permissions && state.account.permissions.onlineTesting);

    return !isOnLine;
  },

  isETL(state: IAuthState, _getters, _rootState, rootGetters): boolean {
    const flowEtl = rootGetters['flowModule/etl'] || false;
    return !!(state.account && state.account.permissions && state.account.permissions.enforcedTimeLimits && flowEtl);
  },

  isLiteETL(state: IAuthState): boolean {
    return !!state.account?.permissions?.liteEtl;
  },

  isOfflineEntryStudent(state: IAuthState): boolean {
    return !!(state.account && state.account.permissions && state.account.permissions.offlineEntry === 'student');
  },

  isHomeRoomOn(state: IAuthState): boolean {
    const homeRoomOn = state.account && state.account.permissions && state.account.permissions.homeRoomOn;

    return !!homeRoomOn;
  },

  isEditionWithoutScores(state: IAuthState): boolean {
    return [605, 606, 607].includes(state.account?.edition_id ?? 0);
  },

  orgPeriods(state: IAuthState): BasicsPeriod[] {
    const { account } = state;
    const { permissions } = account || {};

    return (permissions && permissions.orgPeriods) || [];
  },

  tsiPeriods(state: IAuthState): BasicsPeriod[] {
    const { account } = state;
    const { permissions } = account || {};

    return (permissions && permissions.tsiPeriods) || [];
  },

  studyhallPeriods(state: IAuthState): BasicsPeriod[] {
    const { account } = state;
    const { permissions } = account || {};

    return (permissions && permissions.shPeriods) || [];
  },

  // TODO: This only applies to Assignments, would be better pick a better name
  // because period is normally related to season/windows
  period(state: IAuthState): { start_at: string; end_at: string } | null {
    const { account } = state;
    const { permissions } = account || {};
    const { periods } = permissions || {};

    if (!periods?.length) {
      return {
        // HACK: workaround tsc complain about possible null value
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        start_at: DateTime.now().toISODate()!, // non-nullable type assertion operator
        // HACK: workaround tsc complain about possible null value
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        end_at: DateTime.now().toISODate()!, // non-nullable type assertion operator
      };
    }

    const start = periods[0]?.starts_at;
    const end = periods[periods.length - 1]?.ends_at;

    if (!start || !end) return null;

    return {
      // HACK: workaround tsc complain about possible null value
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      start_at: DateTime.fromISO(start).toISODate()!, // non-nullable type assertion operator
      // HACK: workaround tsc complain about possible null value
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      end_at: DateTime.fromISO(end).toISODate()!, // non-nullable type assertion operator
    };
  },

  getReferrerURL(state: IAuthState): null | string {
    return state.referrerURL;
  },

  hasExamRoom(state: IAuthState, getters: any): boolean {
    return !getters.isETL && getPermissions(state).some((s) => s === 'examroom');
  },

  hasHomeRoom(state: IAuthState, getters: any) {
    return !getters.isETL && getPermissions(state).some((s) => s === 'homeroom');
  },

  hasQuizzes(state: IAuthState, getters: any) {
    return !getters.isETL && getPermissions(state).some((s) => s === 'quizzes');
  },

  hasTSI(state: IAuthState, getters: any) {
    return (
      !getters.isETL &&
      (state.account?.otherProducts?.find((p) => p.product_type.toLowerCase() === BasicProducts.TSI) ||
        state.account?.activeProduct?.product_type.toLowerCase() === BasicProducts.TSI)
    );
  },

  // TODO: need to make this flexible
  hasKSA(state: IAuthState, getters: any) {
    return (
      !getters.isETL &&
      (state.account?.otherProducts?.find((p) => p.product_type.toLowerCase() === BasicProducts.KSA) ||
        state.account?.activeProduct?.product_type.toLowerCase() === BasicProducts.KSA)
    );
  },

  // TODO: need to make this flexible
  hasFSA(state: IAuthState, getters: any) {
    return (
      !getters.isETL &&
      (state.account?.otherProducts?.find((p) => p.product_type.toLowerCase() === BasicProducts.FSA) ||
        state.account?.activeProduct?.product_type.toLowerCase() === BasicProducts.FSA)
    );
  },

  hasReact(state: IAuthState, getters: any) {
    return (
      !getters.isETL &&
      (state.account?.otherProducts?.find((p) => p.product_type.toLowerCase() === BasicProducts.REACT) ||
        state.account?.activeProduct?.product_type.toLowerCase() === BasicProducts.REACT)
    );
  },

  hasStudyHall(state, getters: any) {
    return !getters.isETL && getPermissions(state).some((s) => s === 'studyhall');
  },

  hasDashboard(state: IAuthState, getters: any) {
    return !getters.isETL && getPermissions(state).some((s) => s === 'dashboard');
  },

  // TODO: need to make this flexible
  hasSat(state: IAuthState, getters: any) {
    return (
      state.account?.otherProducts?.find((p) => p.product_type.toLowerCase() === BasicProducts.SAT) ||
      state.account?.activeProduct?.product_type.toLowerCase() === BasicProducts.SAT
    );
  },

  hasVb(state: IAuthState, getters: any) {
    return !getters.isETL && getPermissions(state).some((s) => s === 'vb');
  },

  // TODO: need to make this flexible
  hasGraduationPlanner(state: IAuthState, getters: any) {
    return (
      !getters.isETL &&
      (state.account?.otherProducts?.find((p) => p.product_type.toLowerCase() === BasicProducts.GP) ||
        state.account?.activeProduct?.product_type.toLowerCase() === BasicProducts.GP)
    );
  },

  hasTdm(state: IAuthState) {
    return !!state.account?.permissions?.tdm;
  },

  hasDesmosGraphingCalculator(state: IAuthState) {
    return !!state.account?.permissions?.hasDesmos;
  },

  hasToken(state: IAuthState): boolean {
    return !!state.accessToken;
  },

  // TODO: need to make this flexible
  hasDsat(state: IAuthState, getters: any) {
    return (
      state.account?.otherProducts?.find((p) => p.product_type.toLowerCase() === BasicProducts.DSAT) ||
      state.account?.activeProduct?.product_type.toLowerCase() === BasicProducts.DSAT
    );
  },

  // TODO: need to make this flexible
  hasAct(state: IAuthState, getters: any) {
    return (
      state.account?.otherProducts?.find((p) => p.product_type.toLowerCase() === BasicProducts.ACT) ||
      state.account?.activeProduct?.product_type.toLowerCase() === BasicProducts.ACT
    );
  },
};
