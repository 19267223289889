import type { IContentQuestion } from '@/store/modules/content/types';

export default class NotFinishSectionError extends Error {
  constructor(questions: IContentQuestion[], message: string = '') {
    super(message || 'Error: The section is not finished');

    this.name = 'NotFinishSectionError';
    this.questions = questions;
  }

  public readonly questions: IContentQuestion[];
}
