import type { MutationTree } from 'vuex';
import type { ITimelineAlert, ITimelineCard, ITimelineState } from './types';

type TimelineMutationsTree = MutationTree<ITimelineState>;

export const mutations: TimelineMutationsTree = {
  setTimelineCards(state: ITimelineState, payload: ITimelineCard[]): void {
    const newCards = payload.filter((card) => {
      return state.cards.findIndex((c) => c.id === card.id) === -1;
    });

    state.cards = [...state.cards, ...newCards];
  },

  setHasMore(state: ITimelineState, payload: ITimelineCard[]): void {
    state.hasMoreCards = payload.length > 0;
  },

  setTimelineAlerts(state: ITimelineState, payload: ITimelineAlert[]): void {
    state.alerts = payload;
  },

  clearCards(state: ITimelineState): void {
    state.cards = [];
    state.hasMoreCards = true;
  },
};
