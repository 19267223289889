import type { ActionContext, ActionTree } from 'vuex';
import type { IActionableReportState } from '@/store/modules/report/actionable-report/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type ActionableReportContext = ActionContext<IActionableReportState, RootState>;
type ActionableReportTree = ActionTree<IActionableReportState, RootState>;

export const actions: ActionableReportTree = {
  async fetchActionableReport(context: ActionableReportContext): Promise<any> {
    const reportData = await ApiCaller.getInstance().get(`${context.rootState.productPrefix}report/standards/1`);
    context.commit('setActionableReport', reportData.data);
  },

  async fetchActionableAdaptiveReport(context: ActionableReportContext, testNum: number): Promise<any> {
    const report = await ApiCaller.getInstance().get(`${context.rootState.productPrefix}report/actionable/${testNum}`);
    context.commit('setActionableAdaptiveReport', { window: testNum, report: report.data });
  },
};
