import type { Module } from 'vuex';
import { state } from './state';
import { actions } from './actions';
import { mutations } from './mutations';
import type { IQuizState } from '@/store/modules/quiz/types';
import type { RootState } from '@/store/types';

const namespaced: boolean = true;

export const quizModule: Module<IQuizState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
};
