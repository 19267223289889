import type { MutationTree } from 'vuex';
import type { ITSIEssay, ITSILevels, ITSIState } from '@/store/modules/tsi/types';
import type { IAssessmentReviewQuestion } from '@/store/modules/assessment/types';
import type { IUSection } from '@/store/types';
import type { IPctReview, IStudyHallLesson, IStudyHallPctReview } from '@/store/modules/studyhall/types';

type TSIMutationsTree = MutationTree<ITSIState>;

export const mutations: TSIMutationsTree = {
  setTSI(state: ITSIState, sections: ITSILevels[]) {
    state.levels = sections;
  },

  setLevel(state: ITSIState, level: number) {
    state.currentLevel = level;
  },

  setSubject(state: ITSIState, subject: any) {
    state.currentSubject = subject;
  },

  setAssessments(state: ITSIState, sections: IUSection) {
    state.sections = sections;
  },
  setSection(state: ITSIState, section: IUSection) {
    state.section = section;
  },
  setAnsweredQuestions(state: ITSIState, questions: IAssessmentReviewQuestion[]) {
    state.answeredQuestions = questions;
  },

  setLessons(state: ITSIState, lessons: IStudyHallLesson[]): void {
    state.lessons = lessons;
  },

  setStudyHallPctReviewed(state: ITSIState, pctReviewed: IStudyHallPctReview): void {
    state.lessonsPctReview = pctReviewed;
  },

  setPctReviewed(state: ITSIState, pctReviewed: IPctReview): void {
    state.pctReview = pctReviewed;
  },

  setCurrentLesson(state: ITSIState, currentLesson: IStudyHallLesson): void {
    state.currentLesson = currentLesson;
  },

  setViewedLesson(state: ITSIState, lessonId: number): void {
    const lesson = state.lessons.find((l) => l.id === lessonId);
    if (lesson) lesson.is_reviewed = 1;
  },

  setEssay(state: ITSIState, essay: ITSIEssay): void {
    state.essay = essay;
  },

  setEssayText(state: ITSIState, essayText: string): void {
    if (state.essay) {
      state.essay.essay = essayText;
    }
  },
};
