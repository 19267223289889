import type { ActionContext, ActionTree } from 'vuex';
import type {
  IStudyHallMissedSkipped,
  IStudyHallState,
  SubjectTestPayload,
  LessonTestPayload,
  LessonPayload,
  IStudyHallAssessment,
} from '@/store/modules/studyhall/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type StudyHallActionContext = ActionContext<IStudyHallState, RootState>;
type StudyHallActionTree = ActionTree<IStudyHallState, RootState>;

export const actions: StudyHallActionTree = {
  async clearState(context: StudyHallActionContext) {
    const state: IStudyHallState = {
      lessons: [],
      missedSkipped: [],
      pctReview: null,
      currentLesson: null,
      currentQuestion: null,
      period: context.state.period,
    };

    context.commit('clearState', state);
  },

  async changeCurrentPeriod(context: StudyHallActionContext, period: number): Promise<void> {
    context.commit('changeCurrentPeriod', period);
  },

  async fetchLessons(context: StudyHallActionContext, payload: SubjectTestPayload): Promise<any> {
    const lessons = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}studyhall/lessons/${payload.subject}/${payload.test_num}`
    );

    context.commit('setLessons', lessons.data);
  },

  async fetchPctReviewed(context: StudyHallActionContext, payload: SubjectTestPayload): Promise<any> {
    const pctReviewed = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}studyhall/pct-reviewed/${payload.subject}/${payload.test_num}`
    );

    context.commit('setPctReviewed', pctReviewed.data);
  },

  async loadCurrentLesson(context: StudyHallActionContext, payload: LessonPayload): Promise<any> {
    const { data } = await ApiCaller.getInstance().get<IStudyHallAssessment[]>(
      `${context.rootState.productPrefix}studyhall/assessments/${payload.lesson.id}/${payload.test_num}`
    );

    const { lesson } = payload;
    lesson.assessments = data;

    context.commit('setCurrentLesson', lesson);

    await context.dispatch('fetchMissedSkipped', {
      lessonId: lesson.id,
      test_num: payload.test_num,
    });
  },

  async setViewedLesson(context: StudyHallActionContext, payload: LessonTestPayload): Promise<any> {
    const remedial = {
      remedial_id: payload.lessonId,
      test_num: payload.test_num,
    };

    await ApiCaller.getInstance().post(`${context.rootState.productPrefix}studyhall/viewed/`, remedial);
    context.commit('setViewedLesson', payload.lessonId);
  },

  async setViewedQuestion(context: StudyHallActionContext, questionId: number): Promise<any> {
    const question = {
      question_id: questionId,
    };

    await ApiCaller.getInstance().post(`${context.rootState.productPrefix}assessment/question-viewed/`, question);

    context.commit('setViewedQuestion', questionId);
  },

  async fetchMissedSkipped(context: StudyHallActionContext, payload: LessonTestPayload): Promise<any> {
    const questions = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}studyhall/missed-skipped/${payload.lessonId}/${payload.test_num}`
    );

    context.commit('setMissedSkipped', questions.data);
  },

  async resetMissedSkipped(context: StudyHallActionContext): Promise<any> {
    context.commit('setMissedSkipped', []);
  },

  async setCurrentQuestion(context: StudyHallActionContext, question: IStudyHallMissedSkipped): Promise<any> {
    context.commit('setCurrentQuestion', question);
  },

  async sync(context: StudyHallActionContext): Promise<any> {
    await ApiCaller.getInstance().get(`${context.rootState.productPrefix}studyhall/sync`);
  },

  async testsAvailable(context: StudyHallActionContext): Promise<any> {
    const res = await ApiCaller.getInstance().get(`${context.rootState.productPrefix}studyhall/tests-available`);
    context.commit('setTestsAvailable', res.data as string[]);
  },
};
