<template>
  <base-modal
    v-bind="{
      opened,
      showClose,
      type,
      showCancel,
      cancelLabel,
      modalClasses,
      bodyClasses,
      footerClasses,
      noCloseOnBackdrop,
      buttonCancelDisable,
    }"
    @modal:toggle="onModalToggle"
  >
    <template slot="header">
      <h5 class="modal-title" id="modal-title-notification">{{ title }}</h5>
    </template>

    <slot />

    <template slot="footer">
      <template v-if="buttons">
        <button-component
          v-for="button in buttons"
          v-bind="button"
          :key="button.id"
          @button:click="eventHandler(button.event)"
          :load="buttonsDisable"
          :label-loading="button.label"
        />
      </template>
    </template>
  </base-modal>
</template>
<script lang="ts">
import { Component, Prop, PropSync } from 'vue-property-decorator';
import BaseModal from './template/BaseModal.vue';

@Component({
  name: 'popup-component',
})
export default class PopupComponent extends BaseModal {
  @Prop({ default: '' })
  public title!: string;

  @Prop({ default: '' })
  public content!: string;

  @Prop({ default: '' })
  public onPopupClose!: string;

  @PropSync('buttons', { type: Array })
  public syncedButtons!: [];

  @Prop({ default: false, type: Boolean })
  public buttonCancelDisable!: boolean;

  @Prop({ default: false, type: Boolean })
  public buttonsDisable!: boolean;

  /**
   * Event handler for each button that receives an EMIT
   * @param event
   */
  public eventHandler(event: string): void {
    if (event) {
      this.$emit(event);
    }
  }

  /**
   * Event handler for when you hit close
   * @param value
   */
  public onModalToggle(value: boolean) {
    if (!value && this.onPopupClose) {
      this.$emit(this.onPopupClose, value);
    }
  }
}
</script>
