import type { ActionContext, ActionTree } from 'vuex';
import type { RootState } from '@/store/types';
import api from '@/ApiCaller';
import type { ITimelineCard, ITimelineState } from './types';

type TimelineContext = ActionContext<ITimelineState, RootState>;
type TimelineTree = ActionTree<ITimelineState, RootState>;

export const actions: TimelineTree = {
  async fetchTimelineCards(context: TimelineContext, { page, count }: { page: number; count: number }): Promise<void> {
    const { data } = await api.get<ITimelineCard[]>(`timeline/cards?page=${page}&count=${count}`);
    context.commit('setTimelineCards', data);
    context.commit('setHasMore', data);
  },

  async fetchTimelineAlerts(context: TimelineContext): Promise<void> {
    const { data } = await api.get('timeline/alerts');
    context.commit('setTimelineAlerts', data);
  },

  async clearCards(context: TimelineContext): Promise<void> {
    context.commit('clearCards');
  },
};
