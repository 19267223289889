import type { ActionContext, ActionTree } from 'vuex';
import {
  type IAssessmentState,
  type IAssessmentSubject,
  type ILockSection,
  type IReactHomeRoom,
  PerformanceLabels,
  type IPerformance,
  type ISaveAnswerResponseData,
  type IAssessmentPctReview,
  type IAssessmentReviewQuestion,
  type IQuestionExplanation,
} from '@/store/modules/assessment/types';
import type { IAnswer, IAnswersPayload, RootState } from '@/store/types';
import api from '@/ApiCaller';
import apiReactStudentCaller from '@/ApiReactStudentCaller';
import apiReactStudent from '@/ApiReactStudentCaller';

type AssessmentActionContext = ActionContext<IAssessmentState, RootState>;
type AssessmentActionTree = ActionTree<IAssessmentState, RootState>;

export const actions: AssessmentActionTree = {
  async fetchAssessments(context: AssessmentActionContext): Promise<void> {
    const assessments = await api.get(`${context.rootState.productPrefix}assessments/available`);
    context.commit('setAssessments', assessments.data);
  },

  async fetchRelevance(context: AssessmentActionContext, filter: any): Promise<void> {
    const relevance =
      Object.keys(filter).length > 0 && filter.lesson_id !== 'all'
        ? await api.get(`${context.rootState.productPrefix}assessments/relevance/${filter.subject}/${filter.lesson_id}`)
        : await api.get(`${context.rootState.productPrefix}assessments/relevance`);

    context.commit('setRelevance', relevance.data);
  },

  async fetchCorrectIncorrect(context: AssessmentActionContext, filter: any): Promise<void> {
    const correctIncorrect =
      Object.keys(filter).length > 0 && filter.lesson_id !== 'all'
        ? await api.get(
            `${context.rootState.productPrefix}assessments/correct-incorrect/${filter.subject}/${filter.lesson_id}`
          )
        : await api.get(`${context.rootState.productPrefix}assessments/correct-incorrect`);

    context.commit('setCorrectIncorrect', correctIncorrect.data);
  },

  async fetchPctReview(context: AssessmentActionContext, subject: IAssessmentSubject): Promise<void> {
    let str = `${context.rootState.productPrefix}assessment/pct-reviewed/${subject.subject}/${subject.testNumber}/${subject.sectionNumber}`;

    if (subject.grade) {
      str += `/${subject.grade}`;
    }

    if (subject.courseType) {
      str += `/${subject.courseType}`;
    }

    const pctReviewed = await api.get(str);
    context.commit('setPctReviewed', pctReviewed.data);
  },

  async react_fetchPctReview(context: AssessmentActionContext, subject: any): Promise<void> {
    const pctReviewed = await apiReactStudentCaller.get(`assessment/pct-reviewed/${subject.uSectionId}`);
    const data = pctReviewed.data as { pct_vid_all: number; pct_vid_review: number };
    const pctReview = {
      pct_reviewed_all: data.pct_vid_all || 0,
      pct_reviewed_recommended: data.pct_vid_review || 0,
    } as IAssessmentPctReview;

    context.commit('setPctReviewed', pctReview);
  },

  async takeSection(context: AssessmentActionContext, params: any): Promise<void> {
    let str = `${context.rootState.productPrefix}assessment/take-section/${params.subject}/${params.testNumber}/${params.sectionNumber}`;

    if (params.grade) {
      str += `/${params.grade}`;
    }

    if (params.courseType) {
      str += `/${params.courseType}`;
    }

    const content = await api.get(str);

    context.commit('setSection', content.data);
  },

  async react_takeSection(context: AssessmentActionContext, params: any): Promise<void> {
    let url = `assessment/take-section/${params.subject}`;

    url += params.testNumber ? `/${params.testNumber}` : '';
    url += params.sectionNumber ? `/${params.sectionNumber}` : '';

    const content = await apiReactStudentCaller.get(url);
    context.commit('setSection', content.data);
  },

  async react_reviewSection(context: AssessmentActionContext, params: any): Promise<void> {
    const content = await apiReactStudentCaller.get(`assessment/review-section/${params.uSectionId}`);
    context.commit('setSection', content.data);
    context.commit('setAnsweredQuestions', (content.data as any).answers);
  },

  async fetchAnsweredQuestions(context: AssessmentActionContext, params: any): Promise<void> {
    let str = `${context.rootState.productPrefix}assessment/review-questions/${params.subject}/${params.testNumber}/${params.sectionNumber}`;

    if (params.grade) {
      str += `/${params.grade}`;
    }

    if (params.courseType) {
      str += `/${params.courseType}`;
    }

    const content = await api.get(str);

    context.commit('setAnsweredQuestions', content.data);
  },

  async react_fetchAnsweredQuestions(context: AssessmentActionContext, params: any): Promise<void> {
    const { data } = await apiReactStudentCaller.get(`assessment/review-questions/${params.uSectionId}`);
    const questions = data as IAssessmentReviewQuestion[];
    context.commit('setAnsweredQuestions', questions);
  },

  async fetchAssessmentFeedback(context: AssessmentActionContext, params: any): Promise<void> {
    const content = await api.get(
      `${context.rootState.productPrefix}assessment/feedback/${params.subject}/${params.testNumber}/${params.sectionNumber}`
    );
    context.commit('setAssessmentFeedback', content.data);
  },

  async react_fetchAssessmentFeedback(context: AssessmentActionContext, params: any): Promise<void> {
    const content = await apiReactStudentCaller.get(`assessment/feedback/${params.uSectionId}`);
    context.commit('setAssessmentFeedbackStandards', content.data);
  },

  async fetchQuestionLessons(context: AssessmentActionContext, question_id: number): Promise<void> {
    const lessons = await api.get(`${context.rootState.productPrefix}assessment/question-lessons/${question_id}`);
    context.commit('setQuestionLessons', lessons.data);
  },

  async reviewQuestion(context: AssessmentActionContext, payload: Record<string, unknown>): Promise<void> {
    await api.post(`${context.rootState.productPrefix}assessment/question-viewed`, payload);
  },

  async react_reviewQuestion(context: AssessmentActionContext, payload: Record<string, unknown>): Promise<void> {
    await apiReactStudentCaller.post(`assessment/question-viewed`, payload);
  },

  async feedbackQuestion(context: AssessmentActionContext, payload: Record<string, unknown>): Promise<void> {
    await api.post(`${context.rootState.productPrefix}assessment/question-feedback`, payload);
  },

  async saveAnswer(context: AssessmentActionContext, payload: IAnswer): Promise<string> {
    const response = await api.post(`${context.rootState.productPrefix}assessment/save-answer`, payload);
    const data = response.data as Record<'value', string>;
    return data?.value || '';
  },

  async react_saveAnswer(context: AssessmentActionContext, payload: IAnswer): Promise<void> {
    const response = await apiReactStudentCaller.post(`assessment/save-answer`, payload);
    const data = response.data as ISaveAnswerResponseData;
    if (data.status === 'extended') {
      await context.dispatch('contentModule/addMoreContentQuestions', data?.questions || [], { root: true });
      await context.dispatch('contentModule/addMoreContentPassages', data?.passages || [], { root: true });
      context.commit('updateSectionCt', data.questions?.length || 0);
    }
  },

  async saveAnswers(context: AssessmentActionContext, payload: IAnswersPayload): Promise<void> {
    await api.post(`${context.rootState.productPrefix}assessment/save-answers`, payload);
  },

  async react_saveAnswers(context: AssessmentActionContext, payload: IAnswersPayload): Promise<void> {
    await apiReactStudentCaller.post(`assessment/save-answers`, payload);
  },

  async finishSection(
    context: AssessmentActionContext,
    payload: {
      usectionId: number;
      timeRemain: number;
      is_force_finished: 1 | 0;
      force_finished_by: number;
      is_offline: 1 | 0;
    }
  ): Promise<void> {
    await api.post(`${context.rootState.productPrefix}assessment/finish-section`, {
      usection_id: payload.usectionId,
      time_remain: payload.timeRemain,
      is_force_finished: payload.is_force_finished,
      force_finished_by: payload.force_finished_by,
      is_offline: payload.is_offline,
    });

    const { section } = context.state;
    if (section) {
      context.commit('setSection', {
        ...section,
        is_force_finished: payload.is_force_finished,
        is_completed: 1,
      });
    }
  },

  async react_finishSection(
    context: AssessmentActionContext,
    payload: {
      usectionId: number;
      timeRemain: number;
      is_force_finished: 1 | 0;
      force_finished_by: number;
      is_offline: 1 | 0;
    }
  ): Promise<void> {
    await apiReactStudentCaller.post(`assessment/finish-section`, {
      usection_id: payload.usectionId,
      time_remain: payload.timeRemain,
      is_force_finished: payload.is_force_finished,
      force_finished_by: payload.force_finished_by,
      is_offline: payload.is_offline,
    });

    const { section } = context.state;
    if (section) {
      context.commit('setSection', {
        ...section,
        is_force_finished: payload.is_force_finished,
        is_completed: 1,
      });
    }
  },

  async handleQuestionFeedback(
    _: AssessmentActionContext,
    payload: {
      uquestion_id: number;
      feedback: string;
    }
  ): Promise<void> {
    await api.post(`${_.rootState.productPrefix}assessment/question-feedback`, {
      uquestion_id: payload.uquestion_id,
      feedback: payload.feedback,
    });
  },

  async react_handleQuestionFeedback(
    _: AssessmentActionContext,
    payload: {
      usection_id: string;
      uquestion_id: number;
      feedback: string;
    }
  ): Promise<void> {
    await apiReactStudentCaller.post(`assessment/question-feedback`, {
      usection_id: payload.usection_id,
      question_id: payload.uquestion_id,
      feedback: payload.feedback,
    });
  },

  async takePracticeSection(context: AssessmentActionContext, params: any): Promise<void> {
    const content = await api.get(`practice/section/${params.subject}`);
    context.commit('setSection', content.data);
  },

  async lockSection(context: AssessmentActionContext, payload: ILockSection): Promise<void> {
    await api.post(`${context.rootState.productPrefix}assessment/lock-section`, {
      usection_id: payload.usection_id,
      is_locked: payload.is_locked,
      locked_by: payload.locked_by,
    });
  },

  async setSectionTime(context: AssessmentActionContext, time: number): Promise<void> {
    context.commit('setSectionTime', time);
  },

  async setQuestionTime(
    context: AssessmentActionContext,
    timeValues: { time_spent: number; time_remain: number }
  ): Promise<void> {
    context.commit('setQuestionTime', timeValues);
  },

  async setQuestionExplanation(
    context: AssessmentActionContext,
    payload: { questionId: number; explanation: IQuestionExplanation }
  ): Promise<void> {
    context.commit('setQuestionExplanation', payload);
  },

  async react_fetchHomeRoom(context: AssessmentActionContext): Promise<void> {
    const response = await apiReactStudentCaller.get(`assessment/examroom`);
    const data = response.data as IReactHomeRoom;
    const performanceResult = data.performance || [];
    const sections = data.sections || [];
    const assignments = data.assignments || [];
    const performance: IPerformance = [];

    if (performanceResult.length) {
      performanceResult.forEach((item) => {
        const subject = item.subject === 'Total' ? 'overall' : item.subject;
        performance.push({
          subject: subject,
          label: PerformanceLabels[subject as keyof typeof PerformanceLabels],
          performance: item.performance,
        });
      });
    }

    context.commit('setAssessments', sections);
    context.commit('setPerformance', performance);
    context.commit('setAssignments', assignments);
  },
};
