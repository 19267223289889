<template>
  <component id="theme-container" v-show="show" v-bind:is="currentTheme">
    <slot></slot>
  </component>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { EventBus } from '@/EventBus';
import { Action, Getter } from 'vuex-class';
import type { ThemeConfig } from '@/plugins/config';
import { getThemeKeyByHostName } from '@/plugins/config';

@Component({
  name: 'app-themes',
})
export default class AppThemes extends Vue {
  public show = false;

  @Action('setCurrentTheme')
  public setCurrentTheme!: (theme: ThemeConfig) => Promise<void>;

  @Getter('theme')
  public theme!: ThemeConfig;

  get currentTheme() {
    return () => import(`./themes/${this.theme.component}.vue`);
  }

  public loaded() {
    this.show = true;
  }

  private setTitle(title: string) {
    const slug = window.document.title;
    window.document.title = `${slug} ${title}`;
  }

  private setFavicon(img: string) {
    let favicon = document.querySelector('link[rel="shortcut icon"]');

    if (!favicon) {
      favicon = document.createElement('link');
      favicon.setAttribute('rel', 'shortcut icon');
      const head: any = document.querySelector('head');
      head.appendChild(favicon);
    }

    favicon.setAttribute('type', 'image/png');
    favicon.setAttribute('href', img);
  }

  public async beforeMount() {
    await this.checkTheme();
  }

  public async checkTheme() {
    let theme: ThemeConfig | null = null;
    const themeKey = getThemeKeyByHostName(window.location.hostname, this.$AppConfig.Themes);

    if (themeKey) {
      theme = this.$AppConfig.Themes[themeKey];
    }

    if (theme) {
      await this.configureTheme(theme);
    }
  }

  public async configureTheme(theme: ThemeConfig): Promise<void> {
    await this.setCurrentTheme(theme);
    this.setTitle(theme.title);
    this.setFavicon('/themes/' + theme.name + '/favicon.ico');
  }

  public mounted() {
    EventBus.$on('theme:loaded', this.loaded);
  }
}
</script>
