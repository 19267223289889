import type { MutationTree } from 'vuex';
import type {
  IContentAsset,
  IContentAssets,
  IContentPassage,
  IContentQuestion,
  IContentQuizSection,
  IContentSection,
  IContentState,
} from '@/store/modules/content/types';
import type { IQuestionExplanation } from '@/store/modules/assessment/types';

type ContentMutationsTree = MutationTree<IContentState>;

export const mutations: ContentMutationsTree = {
  setContentSection(state: IContentState, section: IContentSection | IContentQuizSection) {
    state.section = section;
  },

  setContentQuestions(state: IContentState, questions: IContentQuestion[]) {
    state.questions = questions;
  },

  addMoreContentQuestions(state: IContentState, questions: IContentQuestion[] | null) {
    if (state.questions && questions && questions.length) {
      state.questions = state.questions.concat(questions);
    }
  },

  addMoreContentPassages(state: IContentState, passages: IContentPassage[] | null) {
    if (state.passages && passages && passages.length) {
      state.passages = state.passages.concat(passages);
    }
  },

  unsetContentSection(state: IContentState) {
    state.section = null;
  },

  unsetContentQuestions(state: IContentState) {
    state.questions = null;
  },

  setContentPassages(state: IContentState, passages: IContentPassage[]) {
    state.passages = passages;
  },

  addAsset(state: IContentState, payload: { assetKey: string; asset: IContentAsset }) {
    if (payload.asset) {
      state.assets = { ...state.assets, [payload.assetKey]: payload.asset };
    }
  },

  removeAsset(state: IContentState, assetKey: string) {
    if (state.assets) {
      delete state.assets[assetKey];
    }
  },

  unsetQuestions(state: IContentState, payload: { num: number; id: number }[]): void {
    const ids = payload.map((p) => p.id);
    const { questions } = state;

    if (questions && questions.filter((q) => ids.includes(q.id))) {
      for (const question of questions) {
        question.student_answer = '';
      }
    }
  },

  replaceContent(state: IContentState, payload: { questionNum: number; index: number; html: string }): void {
    const { questions } = state;
    const question = questions?.find((question) => question.num === payload.questionNum) as IContentQuestion;
    if (question.content) {
      const content = JSON.parse(question.content);
      content[payload.index]['text'] = payload.html;
      question.content = JSON.stringify(content);
    }
  },

  setQuestionTimeSpent(state: IContentState, timeSpent: { questionId: number; time_spent: number }) {
    if (state.questions) {
      const question = state.questions.find((q) => q.id === timeSpent.questionId);
      if (question) {
        question.time_spent = timeSpent.time_spent;
      }
    }
  },

  setQuestionTimeRemain(state: IContentState, timeRemain: { questionId: number; time_remain: number }) {
    if (state.questions) {
      const question = state.questions.find((q) => q.id === timeRemain.questionId);
      if (question) {
        question.time_remain = timeRemain.time_remain;
      }
    }
  },
};
