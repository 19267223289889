import type { MutationTree } from 'vuex';
import type {
  ITestSectionScoresReportState,
  ITestSectionScoresData,
} from '@/store/modules/report/test-section-scores-report/types';

type TestSectionScoresReportTree = MutationTree<ITestSectionScoresReportState>;

export const mutations: TestSectionScoresReportTree = {
  setTestSectionScores(state: ITestSectionScoresReportState, payload: ITestSectionScoresData): void {
    state.testSectionScores = payload;
  },
};
