import type { Module } from 'vuex';
import { state } from './state';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import type { IErrorState } from '@/store/modules/error/types';
import type { RootState } from '@/store/types';

const namespaced: boolean = true;

type ErrorModule = Module<IErrorState, RootState>;

export const errorModule: ErrorModule = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
