import type { Module } from 'vuex';
import { state } from './state';
import type { IReportState } from '@/store/modules/report/types';
import type { RootState } from '@/store/types';
import { reportsLinksModule } from './reports-links';
import { scoreProgressionModule } from './score-progression';
import { actionableReportModule } from '@/store/modules/report/actionable-report';
import { activityReportModule } from '@/store/modules/report/activity-report';
import { testSectionScoresReportModule } from '@/store/modules/report/test-section-scores-report';
import { lessonPerformanceReportModule } from '@/store/modules/report/lesson-performance-report';

const namespaced: boolean = true;

export const reportModule: Module<IReportState, RootState> = {
  namespaced,
  state,
  modules: {
    scoreProgressionModule,
    actionableReportModule,
    reportsLinksModule,
    activityReportModule,
    testSectionScoresReportModule,
    lessonPerformanceReportModule,
  },
};
