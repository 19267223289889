import type { ActionContext, ActionTree } from 'vuex';
import type { IPracticeAssessmentState, IPracticeAssessmentSubject } from '@/store/modules/practice/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';

type PracticeAssessmentActionContext = ActionContext<IPracticeAssessmentState, RootState>;
type PracticeAssessmentActionTree = ActionTree<IPracticeAssessmentState, RootState>;

export const actions: PracticeAssessmentActionTree = {
  async fetchPracticeAssessments(context: PracticeAssessmentActionContext, sections: any): Promise<any> {
    context.commit('setPracticeAssessments', sections);
  },

  async fetchRelevance(context: PracticeAssessmentActionContext, filter: any): Promise<any> {
    const relevance =
      Object.keys(filter).length > 0 && filter.lesson_id !== 'all'
        ? await ApiCaller.getInstance().get(`assessments/relevance/${filter.subject}/${filter.lesson_id}`)
        : await ApiCaller.getInstance().get('assessments/relevance');

    context.commit('setRelevance', relevance.data);
  },

  async fetchCorrectIncorrect(context: PracticeAssessmentActionContext, filter: any): Promise<any> {
    const correctIncorrect =
      Object.keys(filter).length > 0 && filter.lesson_id !== 'all'
        ? await ApiCaller.getInstance().get(`assessments/correct-incorrect/${filter.subject}/${filter.lesson_id}`)
        : await ApiCaller.getInstance().get('assessments/correct-incorrect');

    context.commit('setCorrectIncorrect', correctIncorrect.data);
  },

  async fetchPctReview(context: PracticeAssessmentActionContext, subject: IPracticeAssessmentSubject): Promise<any> {
    const pctReviewed = await ApiCaller.getInstance().get(
      'assessment/pct-reviewed/' + subject.subject + '/' + subject.testNumber
    );
    context.commit('setPctReviewed', pctReviewed.data);
  },

  async takeSection(context: PracticeAssessmentActionContext, params: any): Promise<any> {
    const content = await ApiCaller.getInstance().get(
      `/assessment/take-section/${params.subject}/${params.testNumber}`
    );
    context.commit('setSection', content.data);
  },

  async fetchAnsweredQuestions(context: PracticeAssessmentActionContext, params: any): Promise<any> {
    const content = await ApiCaller.getInstance().get(
      `/assessment/review-questions/${params.subject}/${params.testNumber}`
    );
    context.commit('setAnsweredQuestions', content.data);
  },

  async fetchPracticeAssessmentFeedback(context: PracticeAssessmentActionContext, params: any): Promise<any> {
    const content = await ApiCaller.getInstance().get(`/assessment/feedback/${params.subject}/${params.testNumber}`);
    context.commit('setPracticeAssessmentFeedback', content.data);
  },

  async fetchQuestionLessons(context: PracticeAssessmentActionContext, question_id: number): Promise<any> {
    const lessons = await ApiCaller.getInstance().get(`/assessment/question-lessons/${question_id}`);
    context.commit('setQuestionLessons', lessons.data);
  },

  async reviewQuestion(_: PracticeAssessmentActionContext, payload: {}): Promise<any> {
    await ApiCaller.getInstance().post('/assessment/question-viewed', payload);
  },

  async feedbackQuestion(_: PracticeAssessmentActionContext, payload: {}): Promise<any> {
    await ApiCaller.getInstance().post('/assessment/question-feedback', payload);
  },

  async finishSection(
    context: PracticeAssessmentActionContext,
    payload: {
      subject: string;
      usectionId: number;
      timeRemain: number;
      answers: any;
      completedSections: {};
    }
  ): Promise<any> {
    await context.dispatch('saveCompletedSections', payload.completedSections);
  },

  async saveCompletedSections(context: PracticeAssessmentActionContext, completedSections: {}): Promise<any> {
    context.commit('setCompletedSections', completedSections);
  },

  async takePracticeSection(context: PracticeAssessmentActionContext, params: any): Promise<any> {
    const content = await ApiCaller.getInstance().get(`/practice/section/${params.subject}`);
    context.commit('setSection', content.data);
  },

  async updatePracticeSection(context: PracticeAssessmentActionContext, section: any): Promise<any> {
    context.commit('setSection', section);
  },

  async saveAnswer(_context: PracticeAssessmentActionContext, _payload: string): Promise<any> {
    //
  },
};
